import React, { useEffect } from 'react';

import { LibraryContentOverview } from '@/components/library/libraryContentOverview/LibraryContentOverview';
import i18n from 'i18next';
import { useSearchParams } from 'react-router-dom';

const LibraryContentOverviewPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlLanguage = searchParams.get('lang');
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

    if (urlLanguage && urlLanguage !== currentLanguageCode) {
      i18n.changeLanguage(urlLanguage).catch((error) => {});
    }
  }, [searchParams, i18n]);
  return <LibraryContentOverview />;
};

export default LibraryContentOverviewPage;
