import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Grid, SelectChangeEvent } from '@mui/material';
import {
  MediaFileUploadService,
  prepBotHistoryGPT,
  RestartRPDialog,
  RPEventType,
  RPMessage,
  RPRunSummary,
  SpeedSelect,
  THQPrimaryButton,
  useAzureRecordingSTT,
  useIdle,
  useMediaFileUploaderService
} from '@trainhq/trainhq-client-core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as Check } from '@/assets/icons/check.svg';
import { ReactComponent as Restart } from '@/assets/icons/restart.svg';
import BotImageFemale from '@/assets/images/bot_female.png';
import BotImageMale from '@/assets/images/bot_male.png';
import RPContextNarrator from '@/assets/images/rp_context_narrator.png';
import UserImageFemale from '@/assets/images/user_female.png';
import UserImageMale from '@/assets/images/user_male.png';
import { useRolePlayInactivityDialogContext } from '@/components/roleplays/inactivityDialog/RolePlayInactivityDialog';
import { ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import { RecordingButton } from '@/roleplay/naturalRolePlay/common/recordingButton/RecordingButton';
import TitleCard from '@/roleplay/naturalRolePlay/common/titleCard/TitleCard';
import XButton from '@/roleplay/naturalRolePlay/common/xButton/XButton';
import {
  createFormRoleplayContext,
  sidebarGoals,
  sidebarObjections,
  TTSReadMessage
} from '@/roleplay/naturalRolePlay/utils';
import WatchModeGoalsAndObjectionsPanel from '@/roleplay/naturalRolePlay/watchMode/goalsPanel/WatchModeGoalsAndObjectionsPanel';
import { RPChatContainer, RPContainer, RPRecordedActionsBar } from '@/roleplay/styles';
import { useTranslation } from 'react-i18next';

const learnerInitialsAvatar = true;

interface NaturalRolePlayProps {
  chatMessages: any[];
  coveredSteps: any[];
  initialSentence?: boolean;
  initializing: boolean;
  sessionUuid: string;
  showValidationCard?: boolean;
  submittingValidation?: boolean;
  ttsSpeed: string;
  changeTtsSpeed(event: SelectChangeEvent): void;
  determineErrorHandling(error: any): void;
  init(): void;
  moveOutOfPracticeMode(): void;
  readMessage(readMessageProps: TTSReadMessage): void;
  setChatMessages: React.Dispatch<React.SetStateAction<any[]>>;
  setCoveredSteps: React.Dispatch<React.SetStateAction<any[]>>;
  setCurrentRunValidationData: React.Dispatch<React.SetStateAction<any>>;
  setShowValidationCard: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmittingValidation: React.Dispatch<React.SetStateAction<boolean>>;
  toggleValidationDialog(): void;
}

const ChatRolePlay: React.FC<NaturalRolePlayProps> = ({
  chatMessages,
  coveredSteps,
  initialSentence,
  initializing,
  sessionUuid,
  showValidationCard,
  submittingValidation,
  ttsSpeed,
  changeTtsSpeed,
  determineErrorHandling,
  init,
  moveOutOfPracticeMode,
  readMessage,
  setChatMessages,
  setCoveredSteps,
  setCurrentRunValidationData,
  setShowValidationCard,
  setSubmittingValidation,
  toggleValidationDialog
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    roleplayExecutionConfig,
    scriptConfig,
    roleplayContext,
    roleplayConfig,
    roleplaySummary,
    refreshRoleplaySummary,
    setRoleplaySummary
  } = useAzureSDKContext();
  const user = useAuthenticatedUserContext();

  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const v2Api = !!searchParams.get('api');

  const mediaFileUploaderServiceRef = useRef<MediaFileUploadService>();
  mediaFileUploaderServiceRef.current = useMediaFileUploaderService();

  const rpService = useRolePlayService();

  const chatContainer = useRef<HTMLDivElement>(null);
  const scrollObserver = useRef<MutationObserver>(null);

  const botImage = searchParams.get('bot') ?? roleplayConfig?.botImage ?? 'f';
  const userImage = searchParams.get('user') ?? roleplayConfig?.userImage ?? 'm';

  const {
    recognitionEndedAsync,
    startingRecord,
    recording,
    setRecording,
    recognizedSpeech,
    setRecognizedSpeech,
    speechEndDetected,
    setSpeechEndDetected,
    sessionStarted,
    startRecording,
    stopRecording
  } = useAzureRecordingSTT({ stt: roleplayExecutionConfig, rpService });

  const [chatStatus, setChatStatus] = useState('');
  const [fadeChat, setFadeChat] = useState(false);
  const [lastUserInput, setLastUserInput] = useState('');
  const [inputHint, setInputHint] = useState('');
  const responseButtonRef = useRef<HTMLButtonElement>(null);
  const recordButtonRef = useRef<HTMLButtonElement>(null);
  const [showHint, setShowHint] = useState(false);
  const [editing, setEditing] = useState(false);
  const [failPresent, setFailPresent] = useState(false);
  const [errorInCommunication, setErrorInCommunication] = useState(false);
  const [restartRPDialogOpen, setRestartRPDialogOpen] = useState(false);
  const [waitForReponse, setWaitForReponse] = useState(false);

  const rolePlayInactivityDialog = useRolePlayInactivityDialogContext();

  const idleCallback = useCallback(() => {
    rolePlayInactivityDialog(roleplayExecutionConfig?.inactivityTimeout, moveOutOfPracticeMode);
  }, [moveOutOfPracticeMode, rolePlayInactivityDialog, roleplayExecutionConfig?.inactivityTimeout]);

  // if the validation is being submitted, do not show the inactivity dialog
  useIdle(
    !submittingValidation && !showValidationCard ? roleplayExecutionConfig?.inactivityTimeout : null,
    idleCallback
  );

  // session start used to work through init, but it's moved to sockets when time tracking for billing was added.
  // init still needs session id because it needs to get the session and work with it on backend
  const initRolePlay = useCallback(
    (reset?: boolean) => {
      setChatStatus('');
      setLastUserInput('');
      setRecognizedSpeech('');
      setChatMessages([]);
      setCoveredSteps([]);
      setFailPresent(false);
      setRecording(false);
      setWaitForReponse(false);
      setCurrentRunValidationData(null);
      setShowValidationCard(false);
      setSubmittingValidation(false);
      if (reset) {
        refreshRoleplaySummary().subscribe({
          next: () => {
            init();
          }
        });
      } else {
        init();
      }
    },
    [
      init,
      refreshRoleplaySummary,
      setChatMessages,
      setCoveredSteps,
      setCurrentRunValidationData,
      setRecognizedSpeech,
      setRecording,
      setShowValidationCard,
      setSubmittingValidation
    ]
  );

  const fadeOutChatAndReset = (reset: boolean) => {
    setFadeChat(true);
    setRestartRPDialogOpen(false);
    setTimeout(() => {
      setFadeChat(false);
      initRolePlay(reset);
    }, 1000);
  };

  const handleReset = () => {
    fadeOutChatAndReset(true);
  };

  useEffect(() => {
    initRolePlay();
  }, [initRolePlay]);

  // TODO: fix deps
  useEffect(() => {
    return () => stopRecording();
  }, []);

  const getMessageAvatar = (identifier: string) => {
    switch (identifier) {
      case 'me':
        return getUserImage();
      case 'narrator':
        return RPContextNarrator;
      case 'bot':
      default:
        return getBotImage();
    }
  };
  const getUserImage = useCallback(() => {
    if (userImage.length > 2) {
      return userImage;
    }
    return userImage === 'm' ? UserImageMale : UserImageFemale;
  }, [userImage]);

  const getBotImage = useCallback(() => {
    if (botImage.length > 2) {
      return botImage;
    }
    return botImage === 'm' ? BotImageMale : BotImageFemale;
  }, [botImage]);

  // TODO: fix deps
  const handleChangeRecognizedSpeech = useCallback((recSpeechEdit: string) => {
    setRecognizedSpeech(recSpeechEdit);
    setEditing(false);
  }, []);

  const handleSubmitMessage = (e, updatedChatHistory?: any, relevantLine?: string) => {
    setWaitForReponse(true);
    setLastUserInput(recognizedSpeech);
    const history = prepBotHistoryGPT({
      chatMessages: chatMessages,
      hasInitialSentence: !!initialSentence,
      prepForSubmit: false,
      updatedChatMessages: updatedChatHistory,
      botName: roleplayConfig.botName,
      userName: roleplayConfig.userName
    });
    const newChatMessagesHistory = updatedChatHistory
      ? [...updatedChatHistory.filter((item, index) => item.content || index === 0)]
      : [...chatMessages.filter((item, index) => item.content || index === 0)];
    setChatMessages([
      '',
      { content: relevantLine ? relevantLine : recognizedSpeech, sender: 'me' },
      ...newChatMessagesHistory
    ]);
    rpService
      .respond(
        {
          sessionUuid,
          step: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
          covered_steps: coveredSteps,
          company: user?.activeCompany?.uuid,
          learner_input: relevantLine ? relevantLine : recognizedSpeech,
          fail_occurred: failPresent
        },
        v2Api
      )
      .subscribe({
        next: (res) => {
          if (res?.status === null) {
            setErrorInCommunication(true);
            setChatStatus('failure');
            return;
          }
          setWaitForReponse(false);
          if (res?.status) {
            setChatStatus(res?.status);
          }
          if (res?.status === 'proceed') {
            setShowHint(false);
            if (res.actual_step || res.actual_step === 0) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
            const newChatMessages = [
              { content: res.message.replace(/^\n|\n$/g, ''), sender: 'bot' },
              { content: recognizedSpeech.replace(/^\n|\n$/g, ''), sender: 'me' },
              ...chatMessages
            ];
            readMessage({
              message: res.message,
              chatMessages: newChatMessages,
              expression: res.expression ?? roleplayConfig?.overallExpression ?? 'general'
            });
          } else {
            if (res?.status === 'failure' && !roleplaySummary.completed) {
              setFailPresent(true);
              setRoleplaySummary({ ...roleplaySummary, finishedInARow: 0 });
              refreshRoleplaySummary().subscribe();
            }
            setInputHint(res?.message);
            if (!failPresent && res?.status !== null && res?.status !== 'failure') {
              setRoleplaySummary({
                ...roleplaySummary,
                finishedInARow: roleplaySummary.finishedInARow + 1
              });
            }
            setChatMessages([{ sender: 'me', content: recognizedSpeech }, ...chatMessages]);
            if (res?.status !== 'failure' && (res.actual_step || res.actual_step === 0)) {
              setCoveredSteps([...coveredSteps, res.actual_step]);
            }
          }
          recordButtonRef?.current?.focus();
        },
        error: (e) => {
          setWaitForReponse(false);
          setErrorInCommunication(true);
          setChatStatus('failure');
          if (e.errorBody.error) {
            determineErrorHandling(e);
          }
          console.log(e);
        }
      });
    setRecognizedSpeech('');
    setSpeechEndDetected(false);
    recordButtonRef?.current?.focus();
  };

  const handleResetSubmit = () => {
    setRecognizedSpeech('');
    recordButtonRef?.current?.focus();
    setSpeechEndDetected(false);
  };

  const undoLastMessage = (errorPresent?: boolean) => {
    const newChatMessages = [...chatMessages];
    newChatMessages.shift();
    if (errorPresent) {
      newChatMessages.shift();
    }
    setErrorInCommunication(false);
    setChatStatus('');
    setLastUserInput('');
    setRecognizedSpeech('');
    setRecording(false);
    setWaitForReponse(false);
    setChatMessages(newChatMessages);
    setShowHint(true);
  };

  const recoverAfterError = () => {
    const newChatMessages = [...chatMessages];
    newChatMessages.shift();
    const lastUserMessage = newChatMessages.shift().content;
    setErrorInCommunication(false);
    setChatStatus('');
    setLastUserInput('');
    setRecognizedSpeech('');
    setRecording(false);
    setWaitForReponse(false);
    setChatMessages(newChatMessages);
    setShowHint(true);
    handleSubmitMessage(undefined, newChatMessages, lastUserMessage);
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const handleGoBackToRoleplays = useCallback(() => {
    stopRecording();
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(ROLEPLAYS);
    }
  }, [navigate, returnTo, stopRecording]);

  const logHintEvent = () => {
    const history = prepBotHistoryGPT({
      chatMessages: chatMessages,
      botName: roleplayConfig.botName,
      userName: roleplayConfig.userName
    });

    const payload = {
      actualStep: coveredSteps[coveredSteps.length - 1],
      stepNumber: Math.floor(initialSentence ? history.length / 2 : (history.length + 1) / 2),
      botResponse: inputHint,
      eventType: RPEventType.HINT,
      company: user?.activeCompany?.uuid,
      journeyUuid: roleplaySummary?.journeyUuid,
      rolePlayUuid: roleplayConfig.rolePlayUuid,
      roleplayID: roleplayConfig.rolePlayID,
      userInput: lastUserInput
    };

    return rpService.logAction(payload);
  };

  const toggleRestartRPDialog = () => {
    setRestartRPDialogOpen((prev) => !prev);
  };

  const scrollOnEvent = () => {
    const target = chatContainer.current;
    setTimeout(() => {
      target.scroll({ top: target.scrollHeight + 200, behavior: 'smooth' });
    }, 120);
  };

  useEffect(() => {
    if (chatContainer.current) {
      scrollObserver.current = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          const nodes = Array.prototype.slice.call(mutation.addedNodes);
          nodes.forEach(function () {
            scrollOnEvent();
          });
        });
      });
      scrollObserver.current.observe(chatContainer.current, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
      });
    }
    return () => {
      scrollObserver.current?.disconnect();
    };
  }, []);

  const handleStopRecording = () => {
    if (stopRecording) {
      stopRecording();
    }
    if (recordButtonRef.current) {
      recordButtonRef.current.blur();
    }
  };

  const submitConvoDisabled = useMemo(() => {
    const convoHappened =
      chatMessages?.[0]?.sender === 'bot' &&
      chatMessages?.some((message) => message.sender === 'me') &&
      chatMessages?.[0] !== '';
    return errorInCommunication || recording || sessionStarted || !!recognizedSpeech || !convoHappened;
  }, [chatMessages, errorInCommunication, recognizedSpeech, recording, sessionStarted]);

  return (
    !initializing && (
      <RPContainer>
        <Grid container columnGap={'16px'}>
          {roleplayConfig?.challengesSidebarEnabled && (
            <Grid item xs={'auto'}>
              <WatchModeGoalsAndObjectionsPanel
                noRPHeader
                rolePlayName={roleplayConfig?.name}
                goals={sidebarGoals(roleplayExecutionConfig?.rolePlaySections, scriptConfig?.jsonGoals)}
                objections={sidebarObjections(
                  roleplayExecutionConfig?.rolePlayObjections,
                  scriptConfig?.objectionsBank
                )}
              />
            </Grid>
          )}
          <Grid item xs={true}>
            <RPContainer accentedBackground={waitForReponse}>
              <div>
                <RestartRPDialog
                  open={restartRPDialogOpen}
                  handleOnClose={toggleRestartRPDialog}
                  handleOnConfirm={handleReset}
                />
                <TitleCard fixed name={roleplayConfig?.name} />
                <XButton onClick={handleGoBackToRoleplays} />
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '40px',
                    right: '40px',
                    transition: 'all .3s ease-out'
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm="auto">
                      <SpeedSelect value={ttsSpeed} onChange={changeTtsSpeed} />
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <THQPrimaryButton onClick={toggleRestartRPDialog} disabled={recording || startingRecord}>
                        <Restart
                          style={{
                            width: '14px',
                            height: '14px',
                            marginRight: '8px'
                          }}
                        />
                        {t('restart')}
                      </THQPrimaryButton>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <THQPrimaryButton variant="main" onClick={toggleValidationDialog} disabled={submitConvoDisabled}>
                        <Check
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '8px'
                          }}
                        />
                        {t('submit_rp')}
                      </THQPrimaryButton>
                    </Grid>
                  </Grid>
                </Box>
                <RPChatContainer ref={chatContainer} fadeChat={fadeChat}>
                  <RPRunSummary
                    roleplayConfig={roleplayConfig}
                    roleplaySummary={roleplaySummary}
                    natural
                    errorInCommunication={errorInCommunication}
                    logHintEvent={logHintEvent}
                    failPresent={failPresent}
                    actualMessage={lastUserInput}
                    preferredMessage={inputHint}
                    onMainClick={handleReset}
                    chatStatus={chatStatus}
                    showHint={showHint}
                    onSecondaryClick={handleGoBackToRoleplays}
                    onRetryLast={undoLastMessage}
                    onRecover={recoverAfterError}
                    visible={chatStatus === 'failure' || chatStatus === 'success' || errorInCommunication}
                  />

                  {sessionStarted && (
                    <RPMessage
                      avatar={getUserImage()}
                      avatarInitialsOnly={learnerInitialsAvatar}
                      content={''}
                      highlight={true}
                      primary={false}
                      side={'right'}
                      user={user}
                    />
                  )}

                  {recognizedSpeech && speechEndDetected && !recording && recognitionEndedAsync && (
                    <RPMessage
                      editable
                      onToggleEditing={toggleEditing}
                      commitEdit={handleChangeRecognizedSpeech}
                      avatar={getUserImage()}
                      avatarInitialsOnly={learnerInitialsAvatar}
                      content={recognizedSpeech}
                      highlight={true}
                      primary={true}
                      side={'right'}
                      user={user}
                    />
                  )}

                  {chatMessages
                    .filter((item, index) => item.content || (index === 0 && !errorInCommunication))
                    .map((cm, index) => (
                      <RPMessage
                        avatar={getMessageAvatar(cm.sender)}
                        key={'' + cm.uuid + '-' + (cm.content ?? '') + (cm.media?.uuid ? cm.media?.uuid : '' + index)}
                        media={cm.media}
                        introText={cm.introText}
                        content={cm.content}
                        highlight={cm.highlight}
                        primary={cm?.primary !== undefined ? cm?.primary : cm.sender === 'me'}
                        side={cm.sender === 'me' ? 'right' : 'left'}
                        avatarInitialsOnly={learnerInitialsAvatar && cm.sender === 'me'}
                        user={user}
                      />
                    ))}
                  <RPMessage
                    lessImportant
                    maxExpand
                    avatar={RPContextNarrator}
                    key={'' + 'roleplayContext_outro'}
                    content={t('rp_summary_content_1')}
                    highlight={false}
                    primary={false}
                    side={'left'}
                    user={user}
                  />
                  {roleplayContext && (
                    <RPMessage
                      lessImportant
                      maxExpand
                      avatar={RPContextNarrator}
                      key={'' + 'roleplayContext'}
                      content={createFormRoleplayContext(roleplayContext)}
                      highlight={false}
                      primary={false}
                      side={'left'}
                      user={user}
                    />
                  )}
                  {roleplayContext && (
                    <RPMessage
                      lessImportant
                      maxExpand
                      avatar={RPContextNarrator}
                      key={'' + 'roleplayContext_intro'}
                      content={t('rp_summary_content_2')}
                      highlight={false}
                      primary={false}
                      side={'left'}
                      user={user}
                    />
                  )}
                </RPChatContainer>
                {chatStatus !== 'failure' && chatStatus !== 'success' && (
                  <div>
                    <RPRecordedActionsBar recognizedSpeech={recognizedSpeech} recording={recording}>
                      <THQPrimaryButton
                        disabled={editing || !recognizedSpeech}
                        ref={responseButtonRef}
                        variant="main"
                        onClick={handleSubmitMessage}
                      >
                        {t('send_response')}
                      </THQPrimaryButton>
                      <div style={{ display: 'flex', gap: '12px' }}>
                        <THQPrimaryButton disabled={editing} onClick={handleResetSubmit}>
                          {t('try_again')}
                        </THQPrimaryButton>
                      </div>
                    </RPRecordedActionsBar>
                    <RecordingButton
                      ref={recordButtonRef}
                      recognizedSpeech={recognizedSpeech}
                      recording={recording}
                      disabled={
                        startingRecord || (recognizedSpeech && speechEndDetected && !recording && waitForReponse)
                      }
                      stopRecording={handleStopRecording}
                      startingRecord={startingRecord}
                      startRecording={startRecording}
                    />
                  </div>
                )}
                {/* <Box sx={{ position: 'fixed', bottom: '40px', left: '40px' }}>
                   <THQTooltip arrow title={'Successfully completed without error'}>
                     <THQProgress
                       asFraction={true}
                       total={roleplayConfig.passRequirement}
                       passed={
                         roleplaySummary.completed
                           ? roleplayConfig.passRequirement
                           : roleplaySummary.finishedInARow > roleplayConfig.passRequirement
                           ? roleplayConfig.passRequirement
                           : roleplaySummary.finishedInARow
                       }
                       size={48}
                       variant="determinate"
                       value={
                         roleplaySummary.completed
                           ? 100
                           : roleplaySummary.finishedInARow > roleplayConfig.passRequirement
                           ? 100
                           : (roleplaySummary.finishedInARow /
                               (roleplayConfig.passRequirement === 0 ? 1 : roleplayConfig.passRequirement)) *
                             100
                       }
                     />
                   </THQTooltip>
                 </Box> */}
              </div>
            </RPContainer>
          </Grid>
        </Grid>
      </RPContainer>
    )
  );
};

export default ChatRolePlay;
