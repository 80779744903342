import React, { useEffect } from 'react';

import TokenAuthComponent from '@/components/authentication/tokenAuth/TokenAuthComponent';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';

const TokenAuthPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlLanguage = searchParams.get('lang');
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

    if (urlLanguage && urlLanguage !== currentLanguageCode) {
      i18n.changeLanguage(urlLanguage).catch((error) => {});
    }
  }, [searchParams, i18n]);
  return <TokenAuthComponent />;
};

export default TokenAuthPage;
