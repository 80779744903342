import React from 'react';

import { NaturalRoleplayScoreCard } from '@trainhq/trainhq-client-core';

import { useGetAttemptDetailsScorecardData } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const AttemptDetailsScorecard: React.FC = () => {
  const scorecardData = useGetAttemptDetailsScorecardData();

  return (
    scorecardData && (
      <NaturalRoleplayScoreCard
        personalPerspective={true}
        results={scorecardData}
        scorecardType="INSIGHTS"
        roleplayName={''}
      />
    )
  );
};

export default AttemptDetailsScorecard;
