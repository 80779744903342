import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import {
  RoleplayBuilder,
  THQAvatar,
  THQBody,
  THQHeadline,
  THQPrimaryButton,
  THQSmallHeadline
} from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from '@/assets/icons/back.svg';
import { InfoIconStyled } from '@/components/roleplays/roleplayList/roleplayDetails/styles';
import { ROLE_PLAY_STRING, ROLEPLAYS } from '@/constants/router';
import { useRolePlayService } from '@/hooks/roleplay/useRolePlayService';
import { useTranslation } from 'react-i18next';

export const RoleplayDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const roleplayID = searchParams.get('roleplay');
  const journeyID = searchParams.get('journey');
  const returnTo = searchParams.get('returnTo');
  const rpService = useRolePlayService();

  const [roleplay, setRoleplay] = useState<RoleplayBuilder>(null);

  useEffect(() => {
    rpService.getRoleplay(roleplayID).subscribe((res) => {
      setRoleplay(res);
    });
  }, [roleplayID, rpService]);

  const handleBack = useCallback(() => {
    navigate(returnTo || ROLEPLAYS);
    setRoleplay(undefined);
  }, [navigate, returnTo]);

  const handleStartRoleplay = useCallback(() => {
    navigate(
      `${ROLE_PLAY_STRING}?roleplay=${roleplayID}${journeyID ? `&journey=${journeyID}` : ''}${
        returnTo ? `&returnTo=${returnTo}` : ''
      }`
    );
    setRoleplay(undefined);
  }, [journeyID, navigate, returnTo, roleplayID]);
  return (
    <>
      {roleplay ? (
        <>
          <Grid
            container
            sx={(theme) => ({
              background: theme.palette.common.zima,
              maxHeight: '100px',
              minHeight: '100px',
              borderBottom: `1px solid ${theme.palette.common.pearl}`
            })}
          >
            <Grid container spacing={'24px'} sx={{ paddingRight: '24px', paddingLeft: '24px', alignItems: 'center' }}>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleBack}>
                  <BackIcon />
                </THQPrimaryButton>
              </Grid>
              <Grid item xs={true}>
                <Grid container>
                  <Grid item xs={12}>
                    <THQSmallHeadline sx={{ fontSize: '14px' }}>{t('roleplays')}</THQSmallHeadline>
                  </Grid>
                  <Grid item xs={12}>
                    <THQHeadline
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: '18px',
                        marginRight: '12px',
                        maxWidth: 'calc(100vw - 150px)'
                      }}
                    >
                      {roleplay?.name}
                    </THQHeadline>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={'auto'}>
                <THQPrimaryButton variant="main" onClick={handleStartRoleplay}>
                  {t('start_rp')}
                </THQPrimaryButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} paddingRight={'24px'} paddingLeft={'24px'}>
            <Grid item md={8}>
              <Grid container gap={'40px'}>
                <Grid sx={{ marginTop: '24px' }} item xs={12}>
                  <THQHeadline size="xl">{t('requirements')}</THQHeadline>
                </Grid>
                {roleplay.rolePlayType === 'STRICT' ? (
                  <Grid item xs={12} display="flex" alignItems={'center'}>
                    <InfoIconStyled />
                    <THQBody
                      size="large"
                      sx={(theme) => ({
                        lineHeight: '28px',
                        ...(!roleplay.description && { color: theme.palette.common.silver })
                      })}
                    >
                      {t('rp_have_to_complete')}{' '}
                      <b>
                        {roleplay.passRequirement ?? 1}{' '}
                        {roleplay.passRequirement && roleplay.passRequirement > 1 ? t('times_in_a_row') : t('time')}
                      </b>{' '}
                      {t('rp_have_to_complete_without_errors')}
                    </THQBody>
                  </Grid>
                ) : (
                  <Grid item xs={12} display="flex" alignItems={'center'}>
                    <Grid container gap={'16px'}>
                      <Grid item xs={'auto'}>
                        <InfoIconStyled />
                      </Grid>
                      <Grid item xs>
                        <THQBody
                          size="large"
                          sx={(theme) => ({
                            lineHeight: '28px',
                            ...(!roleplay.description && { color: theme.palette.common.silver })
                          })}
                        >
                          {t('rp_practicing_text_1')}{' '}
                          <b>
                            {roleplay.passRequirement ?? 1}{' '}
                            {roleplay.passRequirement && roleplay.passRequirement > 1
                              ? t('datetime.times')
                              : t('datetime.time')}
                          </b>
                          {t('rp_practicing_text_2')}
                        </THQBody>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid sx={{ marginTop: '24px' }} item xs={12}>
                  <THQHeadline size="xl">{t('overview')}</THQHeadline>
                </Grid>
                <Grid item xs={12}>
                  <THQHeadline sx={{ marginBottom: '16px' }} size="medium">
                    {t('description')}
                  </THQHeadline>
                  <THQBody size="large">{roleplay.description ? roleplay.description : t('no_description')}</THQBody>
                </Grid>
                {roleplay?.owner && (
                  <Grid item xs={12}>
                    <THQHeadline sx={{ marginBottom: '16px' }} size="medium">
                      {t('course_owner')}
                    </THQHeadline>
                    <Grid sx={{ marginBottom: '24px' }} container alignItems={'center'} gap={'12px'}>
                      <Grid item>
                        {roleplay?.owner?.profilePicUrl ? (
                          <THQAvatar src={roleplay?.owner?.profilePicUrl} />
                        ) : (
                          <THQAvatar colorRandomizeString={`${roleplay?.owner?.firstName}${roleplay?.owner?.lastName}`}>
                            {roleplay?.owner?.firstName?.[0]}
                            {roleplay?.owner?.lastName?.[0]}
                          </THQAvatar>
                        )}
                      </Grid>
                      <Grid item>
                        <THQBody size="large">
                          {roleplay?.owner?.firstName} {roleplay?.owner?.lastName}
                        </THQBody>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>{t('loading_lower')}</>
      )}
    </>
  );
};
