import { BaseCloudService, CallIntelligenceBuilder, Pageable, PageRequest } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  CALL_INTELLIGENCE_AVAILABLE_CALL_TYPES,
  CALL_INTELLIGENCE_CALLS,
  CALL_INTELLIGENCE_GET,
  CALL_INTELLIGENCE_PAGE
} from '@/constants/api';
import { RolePlaySessionDto } from '@/models/learnerModels';

export interface ChallengesGenerationPayload {
  rolePlayUuid: string;
  goalsGenerationPrompt?: string;
  objectionsGenerationPrompt?: string;
}

export class CallIntelligenceCloudService extends BaseCloudService<CallIntelligenceBuilder> {
  constructor() {
    super({
      page: CALL_INTELLIGENCE_PAGE,
      get: CALL_INTELLIGENCE_GET
    });
  }

  getAvailableCallTypes(): Observable<RolePlaySessionDto> {
    return this.networkService.get(`${CALL_INTELLIGENCE_AVAILABLE_CALL_TYPES}`);
  }

  callsPage(pageRequest: PageRequest): Observable<Pageable<any>> {
    return this.networkService.post(CALL_INTELLIGENCE_CALLS, pageRequest);
  }
}
