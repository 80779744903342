import React from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  insightsHeaderHeight,
  mobileInsightsHeaderHeight,
  THQCourseProgressChip,
  THQMainPageContainer,
  UserStatus
} from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import NaturalRolePlay from '@/components/insights/role-play/naturalRolePlay/NaturalRolePlay';
import StrictRolePlay from '@/components/insights/role-play/strictRolePlay/StrictRolePlay';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetRPUserStatus } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const RolePlayInsights: React.FC = () => {
  const context = useSelectedRPInsightsTabContext();
  const theme = useTheme();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useAuthenticatedUserContext();

  const status = useGetRPUserStatus(rolePlayUuid);

  return (
    <THQMainPageContainer
      sx={{ height: 'auto', marginTop: `${isMobile ? mobileInsightsHeaderHeight : insightsHeaderHeight}px` }}
    >
      <Typography fontWeight={700} sx={{ marginBottom: '32px' }} variant="h2">
        {user.firstName} {user.lastName}{' '}
        <THQCourseProgressChip courseProgressStatus={status?.progressState} sx={{ height: '22px' }} />
      </Typography>
      {status && <UserStatus status={status} />}
      {context?.selectedRolePlay &&
        (context.selectedRolePlay.rolePlayType === 'STRICT' ? <StrictRolePlay /> : <NaturalRolePlay />)}
    </THQMainPageContainer>
  );
};

export default RolePlayInsights;
