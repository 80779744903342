import React, { useCallback, useMemo, useState } from 'react';

import { AttemptHistoryTable } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router-dom';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { INSIGHTS, ROLE_PLAY_STRING } from '@/constants/router';
import { useGetSubmittedNRPReports } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const getSuccessRateBoundary = (successRateRange: string, upper?: boolean) => {
  switch (successRateRange) {
    case 'All':
      return undefined;
    case '59':
      return upper ? 60 : 0;
    case '69':
      return upper ? 70 : 60;
    case '79':
      return upper ? 80 : 70;
    case '89':
      return upper ? 90 : 80;
    case '90':
      return upper ? 101 : 90;
  }
};

const NaturalResponsesTabPanel: React.FC = () => {
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();

  const [createdAtBefore, setCreatedAtBefore] = useState(0);
  const [createdAtAfter, setCreatedAtAfter] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [successRate, setSuccessRate] = useState<unknown>('All');

  const handleSetCreatedAtAfter = (e) => {
    if (e === null) {
      setCreatedAtAfter(0);
    } else {
      const newTime = new Date(e).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtAfter(newTime);
    }
  };

  const handleSetSuccessRate = useCallback((e) => {
    setSuccessRate(e.target.value);
  }, []);

  const handleSetCreatedAtBefore = (selectedDate: number) => {
    if (selectedDate === null) {
      setCreatedAtBefore(0);
    } else {
      const newTime = new Date(selectedDate).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtBefore(newTime);
    }
  };

  const handleChangePage = (e, val) => {
    setPage(val);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const handleOpenDetails = (reportUuid: string) => {
    window.open(`${window.location.origin}${INSIGHTS}${ROLE_PLAY_STRING}/${rolePlayUuid}/${reportUuid}`);
  };

  const { tabValue } = useSelectedRPInsightsTabContext();

  const request = useMemo(
    () => ({
      rolePlayUuid,
      successRateFrom: getSuccessRateBoundary(successRate as string, false),
      successRateTo: getSuccessRateBoundary(successRate as string, true),
      createdAtBefore,
      createdAtAfter,
      page,
      rowsPerPage
    }),
    [rolePlayUuid, successRate, createdAtBefore, createdAtAfter, page, rowsPerPage]
  );

  const { logResults, loadingLogResults, totalElements } = useGetSubmittedNRPReports(request, tabValue);

  const skip = tabValue !== 2;
  return skip ? null : (
    <AttemptHistoryTable
      createdAtAfter={createdAtAfter}
      createdAtBefore={createdAtBefore}
      loadingLogResults={loadingLogResults}
      logResults={logResults}
      successRate={successRate as string}
      onChangeCreatedAtAfter={handleSetCreatedAtAfter}
      onChangeCreatedAtBefore={handleSetCreatedAtBefore}
      onOpenDetails={handleOpenDetails}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      totalElements={totalElements}
      page={page}
      rowsPerPage={rowsPerPage}
      setSuccessRate={handleSetSuccessRate}
    />
  );
};

export default NaturalResponsesTabPanel;
