import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  THQDialog,
  THQDialogActionButton,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  useTHQSnackbar
} from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Subscription, timer } from 'rxjs';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import { COURSES } from '@/constants/router';
import { useTranslation } from 'react-i18next';

type CourseInactivityDialogContext = (inactivityPeriodInMinutes: number) => void;

const context = createContext<CourseInactivityDialogContext>(null);
export const useCourseInactivityDialogContext = () => useContext(context);

interface CourseInactivityDialogProps {
  children?: React.ReactNode;
}

const CourseInactivityDialog: React.FC<CourseInactivityDialogProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabToReturn = location.state === ActiveTab.COMPLETED ? '?activeTab=completed' : '?activeTab=your-course';
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const snackbar = useTHQSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [inactivityPeriodInMinutes, setInactivityPeriodInMinutes] = useState<number>();
  const [timeTillExpire, setTimeTillExpire] = useState<number>(60);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setTimeTillExpire(60);
  }, []);

  useEffect(() => {
    const subs = new Subscription();
    if (open) {
      subs.add(
        timer(0, 1000).subscribe(() => {
          setTimeTillExpire((prevState) => prevState - 1);
        })
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [open]);

  useEffect(() => {
    if (timeTillExpire === 0) {
      handleOnClose();
      if (returnTo) {
        navigate(returnTo);
      } else {
        navigate(`${COURSES}${tabToReturn}`);
      }
      snackbar(t('you_were_timed_out'));
    }
  }, [handleOnClose, navigate, returnTo, snackbar, tabToReturn, timeTillExpire]);

  const contextValue = useCallback((inactivityPeriodInMinutes: number) => {
    setOpen(true);
    setInactivityPeriodInMinutes(inactivityPeriodInMinutes);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} onClose={handleOnClose}>
        <THQDialogTitle>{t('course_inactivity_title')}</THQDialogTitle>
        <THQDialogContent>
          <Typography>
            {t('course_inactivity_question_1')} {inactivityPeriodInMinutes}{' '}
            {inactivityPeriodInMinutes === 1 ? t('datetime.minute') : t('datetime.minutes')}.{' '}
            {t('course_inactivity_question_2')}
          </Typography>
          <Typography>
            {t('course_inactivity_expire')}{' '}
            <Typography component="span" fontWeight={700} sx={{ marginTop: '16px' }}>
              {timeTillExpire} {timeTillExpire === 1 ? t('datetime.second') : t('datetime.seconds')}
            </Typography>
            .
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <Grid container justifyContent="end" spacing={1}>
            <Grid item sm="auto" xs={12}>
              <THQDialogActionButton variant="main" onClick={handleOnClose}>
                {t('course_inactivity_yes')}
              </THQDialogActionButton>
            </Grid>
          </Grid>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default CourseInactivityDialog;
