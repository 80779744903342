import React, { useEffect, useMemo, useState } from 'react';

import { Grid, Radio, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ProgressStatus, THQHeadline, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router-dom';
import { Observable } from 'rxjs';

import { ProgressTableCell, TableCellStyled, TableStyled } from '../scorecardTabs/styles';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { useRolePlayAnalyticsService } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';
import { RPInlineLoader } from '@/roleplay/components/loader/RPinlineLoader';
import { useTranslation } from 'react-i18next';

interface NaturalRolePlaySelfAssessmentProps {
  results: any;
  moveToScorecard: () => void;
  refreshReport: () => Observable<any>;
}

const NaturalRolePlaySelfAssessment: React.FC<NaturalRolePlaySelfAssessmentProps> = ({
  results,
  refreshReport,
  moveToScorecard
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    roleplayContext,
    roleplayConfig,
    roleplayExecutionConfig,
    scriptConfig,
    roleplaySummary,
    refreshRoleplaySummary,
    setRoleplaySummary
  } = useAzureSDKContext();
  const [goals, setGoals] = useState<any[]>(null);
  const [objections, setObjections] = useState<any[]>(null);
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [objectionLoaderVisible, setObjectionLoaderVisible] = useState(true);

  useEffect(() => {
    if (roleplayExecutionConfig.rolePlaySections) {
      const parsedGoals = roleplayExecutionConfig?.rolePlaySections?.reduce((acc, item) => {
        return [...acc, ...item.goals.map((g) => ({ ...g, section: item.section }))];
      }, []);
      let flatGoalMap = [];
      parsedGoals.forEach((goal) => {
        flatGoalMap.push({ ...goal, section: goal.section, completed: null });
      });

      flatGoalMap = flatGoalMap?.sort((a, b) => a?.order - b?.order);
      setGoals(flatGoalMap);
      // setGoals(parsedGoals);
    } else if (scriptConfig?.jsonGoals) {
      const goals = JSON.parse(scriptConfig.jsonGoals);
      const flatGoalMap = [];
      goals.forEach((rootGoal) => {
        rootGoal.goals.forEach((goal) => {
          flatGoalMap.push({ ...goal, section: rootGoal.section, completed: null });
        });
      });
      setGoals(flatGoalMap);
    }
  }, [scriptConfig.jsonGoals, roleplayExecutionConfig?.rolePlaySections]);

  useEffect(() => {
    if (results && results.objectionVerificationReportData && roleplayExecutionConfig?.rolePlayObjections) {
      let parsedObjections = results?.objectionVerificationReportData?.objections ?? [];
      parsedObjections = parsedObjections
        ?.map((item) => {
          const twin = roleplayExecutionConfig?.rolePlayObjections.find((existing) => existing.uuid === item.uuid);
          if (twin) {
            return { ...item, order: twin.order };
          }
        })
        // ?.filter((item) => item.order !== undefined && item.order !== null)
        ?.sort((a, b) => a.order - b.order);

      const flatObjectionMap = [];
      parsedObjections.forEach((objection) => {
        flatObjectionMap.push({ ...objection, completed: objection.completed ? objection.completed : null });
      });
      setObjections(flatObjectionMap);
    } else {
      setObjections([]);
    }
  }, [results, results?.objectionVerificationReportData, roleplayExecutionConfig?.rolePlayObjections]);

  const setEvaluationForGoal = (goalIndex: number, evaluation: boolean) => {
    const newGoals = [...goals];
    newGoals[goalIndex].completed = evaluation;
    setGoals(newGoals);
  };

  const setEvaluationForObjection = (objectionIndex: number, evaluation: boolean) => {
    const newObjections = [...objections];
    newObjections[objectionIndex].completed = evaluation;
    setObjections(newObjections);
  };

  const selfAssessmentDone = useMemo(() => {
    return !(goals?.some((item) => item.completed === null) || objections?.some((item) => item.completed === null));
  }, [goals, objections]);

  const handleMoveToScorecard = () => {
    const payload = {
      reportUuid: results?.reportUuid,
      goals: goals.map((item) => ({
        uuid: item.uuid,
        evaluation: item.completed ? ProgressStatus.COMPLETED : ProgressStatus.NOT_COMPLETED
      })),
      objections: objections.map((item) => ({
        uuid: item.uuid,
        evaluation: item.completed ? ProgressStatus.COMPLETED : ProgressStatus.NOT_COMPLETED
      }))
    };
    rpAnalyticsService.addSelfAssessment(payload).subscribe({
      next: (res) => {
        refreshReport().subscribe((res) => {
          moveToScorecard();
        });
      }
    });
  };
  return (
    <div>
      <Grid container justifyContent={'center'} sx={{ marginTop: '56px' }}>
        <Grid item xs={8} sx={{ marginBottom: '32px' }}>
          <THQHeadline sx={{ textAlign: 'center', fontSize: '18px' }}>{t('rp_self_1')}</THQHeadline>
          <THQHeadline sx={{ textAlign: 'center', fontWeight: 400 }}>{t('rp_self_2')}</THQHeadline>
        </Grid>
        <Grid item xs={8}>
          <Grid sx={{ marginTop: 0, marginBottom: '32px' }} container>
            <div style={{ display: 'grid', width: '100%' }}>
              <TableContainer
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.common.paleSilver}`,
                  borderRadius: '12px'
                })}
              >
                <TableStyled aria-label="table" noMargin noBorder>
                  <TableHead>
                    <TableRow>
                      <TableCellStyled>{t('goals')}</TableCellStyled>
                      <TableCellStyled align="center" sx={{ width: 48, padding: 0 }}>
                        ❌
                      </TableCellStyled>
                      <TableCellStyled align="center" sx={{ width: 48, padding: 0 }}>
                        ✅
                      </TableCellStyled>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!goals || goals?.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <Typography fontSize={14}>{t('no_goals_found')}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {(goals || []).map((item, goalIndex) => (
                      <TableRow key={`${goalIndex}`}>
                        <ProgressTableCell>
                          <Box sx={{ whiteSpace: 'break-spaces' }}>{item.goal || '-'}</Box>
                        </ProgressTableCell>
                        <ProgressTableCell align="center" sx={{ width: 48, padding: 0 }}>
                          <Radio
                            sx={(theme) => ({ color: theme.palette.common.steel })}
                            checked={item.completed === false}
                            size="small"
                            onClick={() => setEvaluationForGoal(goalIndex, false)}
                          />
                        </ProgressTableCell>
                        <ProgressTableCell align="center" sx={{ width: 48, padding: 0 }}>
                          <Radio
                            sx={(theme) => ({ color: theme.palette.common.steel })}
                            checked={item.completed === true}
                            size="small"
                            onClick={() => setEvaluationForGoal(goalIndex, true)}
                          />
                        </ProgressTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TableStyled>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid sx={{ marginTop: 0, marginBottom: '120px' }} container>
            <div style={{ display: 'grid', width: '100%' }}>
              <TableContainer
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.common.paleSilver}`,
                  borderRadius: '12px'
                })}
              >
                <TableStyled aria-label="table" noMargin noBorder>
                  <TableHead>
                    <TableRow>
                      <TableCellStyled>{t('objections')}</TableCellStyled>
                      <TableCellStyled align="center" sx={{ width: 48, padding: 0 }}>
                        ❌
                      </TableCellStyled>
                      <TableCellStyled align="center" sx={{ width: 48, padding: 0 }}>
                        ✅
                      </TableCellStyled>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {!results && (
                        <TableCellStyled colSpan={8}>
                          <RPInlineLoader
                            visible={objectionLoaderVisible}
                            loaderMessages={[t('searching_objections')]}
                            totalLength={180}
                          />
                        </TableCellStyled>
                      )}
                    </TableRow>
                    {results && (!objections || objections?.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <Typography fontSize={14} sx={{ textAlign: 'center' }}>
                            {t('no_objections_found')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {results &&
                      (objections || []).map((item, objectionIndex) => (
                        <TableRow key={`${objectionIndex}`}>
                          <ProgressTableCell>
                            <Box sx={{ whiteSpace: 'break-spaces' }}>{item.objection_match || '-'}</Box>
                          </ProgressTableCell>
                          <ProgressTableCell align="center" sx={{ width: 48, padding: 0 }}>
                            <Radio
                              sx={(theme) => ({ color: theme.palette.common.steel })}
                              checked={item.completed === false}
                              size="small"
                              onClick={() => setEvaluationForObjection(objectionIndex, false)}
                            />
                          </ProgressTableCell>
                          <ProgressTableCell align="center" sx={{ width: 48, padding: 0 }}>
                            <Radio
                              sx={(theme) => ({ color: theme.palette.common.steel })}
                              checked={item.completed === true}
                              size="small"
                              onClick={(e) => setEvaluationForObjection(objectionIndex, true)}
                            />
                          </ProgressTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </TableStyled>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          position: 'fixed',
          bottom: '40px',
          right: '40px',
          transition: 'all .3s ease-out'
        }}
      >
        <THQPrimaryButton variant="main" onClick={handleMoveToScorecard} disabled={!selfAssessmentDone || !results}>
          {t('show_my_results')}
        </THQPrimaryButton>
      </Box>
    </div>
  );
};

export default NaturalRolePlaySelfAssessment;
