import React, { useState } from 'react';

import { THQAccordion, THQBody, THQHeadline, THQTab, THQTabContainer } from '@trainhq/trainhq-client-core';

import AccordionContent from '@/roleplay/naturalRolePlay/common/challengesPanel/accordion/AccordionContent';
import {
  DividerBoxStyled,
  DividerStyled,
  RoundedBoxStyled,
  TabPanelStyled,
  TitleTextStyled
} from '@/roleplay/naturalRolePlay/common/challengesPanel/styles';
import { useTranslation } from 'react-i18next';

interface ChallengesPanelProps {
  goals: any[]; // TODO: define the type (there's some issue with current RolePlayGoal type definition)
  objections: any[]; // TODO: define the type (there's some issue with current RolePlayObjection type definition)
  rolePlayName: string;
}

const ChallengesPanel: React.FC<ChallengesPanelProps> = ({ goals, objections, rolePlayName }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTabIndex = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <RoundedBoxStyled>
      <TitleTextStyled>{t('challenges')}</TitleTextStyled>
      <THQTabContainer
        sx={{ paddingLeft: '24px', paddingRight: '24px' }}
        value={tabIndex}
        onChange={handleChangeTabIndex}
      >
        {goals && (
          <THQTab
            tabIndex={0}
            sx={{ fontWeight: 700, marginBottom: '24px', padding: '12px 4px', minWidth: 'unset' }}
            label={t('goals')}
          />
        )}
        {objections && objections?.length > 0 && (
          <THQTab tabIndex={1} sx={{ fontWeight: 700, marginBottom: '24px' }} label={t('objections')} />
        )}
      </THQTabContainer>
      <TabPanelStyled value={tabIndex} index={0}>
        {goals?.map((item, index) => (
          <React.Fragment key={item.uuid}>
            <THQHeadline sx={{ marginBottom: '16px' }}>{item.section}</THQHeadline>
            {item?.goals?.map((goal) => (
              <THQBody sx={{ marginBottom: '16px' }} key={goal.uuid}>
                {goal.goal}
              </THQBody>
            ))}
            {index !== goals?.length - 1 && (
              <DividerBoxStyled>
                <DividerStyled />
              </DividerBoxStyled>
            )}
          </React.Fragment>
        ))}
      </TabPanelStyled>
      <TabPanelStyled value={tabIndex} index={1}>
        {objections?.map((item, index) => (
          <React.Fragment key={item.uuid}>
            <THQAccordion
              headline={<THQHeadline>{item.objectionMatch}</THQHeadline>}
              content={<AccordionContent objection={item} />}
            />
            {index !== objections?.length - 1 && (
              <DividerBoxStyled>
                <DividerStyled />
              </DividerBoxStyled>
            )}
          </React.Fragment>
        ))}
      </TabPanelStyled>
    </RoundedBoxStyled>
  );
};

export default ChallengesPanel;
