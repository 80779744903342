import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import {
  blobToFile,
  LearnerFeedbackThread,
  MediaFile,
  MediaFileUploadService,
  RecorderFileMetadata,
  THQPrimaryButton,
  useMediaFileUploaderService
} from '@trainhq/trainhq-client-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { finalize } from 'rxjs';

import QuizRecordingStudio from '@/components/common/quizRecordingStudio/QuizRecordingStudio';
import { renderMediaContent } from '@/components/course/block/common/quizmediaRender/QuizMediaRender';
import { ConfirmAnswerButton, ContentContainerStyled } from '@/components/course/block/common/styles';
import BlockNav from '@/components/course/block/navigation/BlockNav';
import {
  FeedbackContent,
  FeedbackHeadline,
  FeedbackMessageContainer,
  QuestionInputStyled,
  StyledPlayer,
  StyledPlayerControls,
  StyledRecorder,
  TypographyQuizQuestion
} from '@/components/course/block/quiz/styles';
import { useCourseContext } from '@/components/course/courseDetails/context';
import { HOME } from '@/constants/router';
import { useFeedbackService } from '@/hooks/feedback/useCourseService';
import { LearnerBlock } from '@/models/learnerModels';
import { resolveAndMapFeedbackStatus } from '@/utils/courseUtils';
import { generateMediaFileUuid } from '@/utils/uuidUtils';
import { useTranslation } from 'react-i18next';

interface QuizComponentProps {
  block: LearnerBlock;
  journeyUuid?: string;
}

// TODO: split this into smaller common
// TODO: avoid using this many `sx` props, move styles to styles
const Quiz: React.FC<QuizComponentProps> = ({ block, journeyUuid }) => {
  const { t } = useTranslation();
  const { course, isLast, previewModeOn, sidebarExpanded, setCourse } = useCourseContext();

  const navigate = useNavigate();
  const feedbackService = useRef(useFeedbackService()).current;
  const [searchParams] = useSearchParams();
  const isRespond = searchParams.get('respond') == '1';
  const mediaFileUploaderServiceRef = useRef<MediaFileUploadService>();
  mediaFileUploaderServiceRef.current = useMediaFileUploaderService();

  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [mediaContent, setMediaContent] = useState<MediaFile>(null);
  const [feedbackThread, setFeedbackThread] = useState<LearnerFeedbackThread>({ uuid: undefined });
  const [confirmingResponse, setConfirmingResponse] = useState<string>('');
  const [recStudioOpen, setRecStudioOpen] = useState<boolean>(false);
  const [recording, setRecording] = useState<boolean>(false);

  // TODO: Fix deps. There's some bug when we remove block?.quizFeedbackStatus
  const quizFeedbackState: 'NOT_STARTED' | 'COMPLETED' | 'SUBMITTED' | 'RETRY_REQUIRED' = useMemo(() => {
    return resolveAndMapFeedbackStatus(feedbackThread);
  }, [feedbackThread, block?.quizFeedbackStatus]);

  const answerCommitted = previewModeOn
    ? feedbackThread?.threadStatus === 'PENDING_RESPONSE'
    : !!(
        (feedbackThread?.learnerFeedback?.message || feedbackThread?.learnerFeedback?.attachment) &&
        (quizFeedbackState === 'NOT_STARTED' || quizFeedbackState === 'COMPLETED' || quizFeedbackState === 'SUBMITTED')
      );

  useEffect(() => {
    setFeedbackThread({ uuid: undefined });
    setAnswer('');
    setMediaContent(null);
    if (block?.quiz?.uuid && block.quizFeedbackStatus && !previewModeOn) {
      setLoading(true);
      feedbackService
        .getPendingThread(block.uuid, journeyUuid)
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe({
          next: (thread) => {
            setFeedbackThread(thread);
            if (
              thread.threadStatus === 'APPROVER_REJECTED' ||
              thread.threadStatus === 'PENDING_RESPONSE' ||
              thread.threadStatus === 'ACCEPTED'
            ) {
              setMediaContent(thread?.learnerFeedback?.attachment);
              setAnswer(thread?.learnerFeedback?.message);
            }
          }
        });
    }
  }, [block?.quiz?.uuid, block?.quizFeedbackStatus, block?.uuid, feedbackService, previewModeOn, journeyUuid]);

  const handleChangeAnswer = (e) => {
    if (e.target.value.length <= 3000) {
      setAnswer(e.target.value);
    }
  };

  const handleSaveMedia = () => {
    const updatedThread = {
      ...feedbackThread,
      learnerFeedback: {
        ...feedbackThread.learnerFeedback,
        attachment: mediaContent,
        uuid: undefined
      }
    };
    setFeedbackThread(updatedThread);
  };

  const handleOpenNextBlockUpdateQuiz = () => {
    if (quizFeedbackState === 'SUBMITTED') {
      setAnswer('');
      setMediaContent(null);
    } else {
      if (quizFeedbackState !== 'COMPLETED') {
        setCourse((c) => ({
          ...c,
          userCompleted: isLast,
          sections: c.sections.map((s) => ({
            ...s,
            blocks: s.blocks.map((b) =>
              block.uuid === b.uuid
                ? {
                    ...b,
                    quizFeedbackStatus: 'PENDING_RESPONSE'
                  }
                : b
            )
          }))
        }));
      }
    }
  };

  // TODO: refactor this together with the remaining learner. Way too many flags.
  const confirmAnswer = () => {
    setConfirmingResponse(t('confirming'));
    if (previewModeOn) {
      setCourse((c) => ({
        ...c,
        sections: c.sections.map((s) => ({
          ...s,
          blocks: s.blocks.map((b) =>
            block.uuid === b.uuid
              ? {
                  ...b,
                  quizFeedbackStatus: 'PENDING_RESPONSE'
                }
              : b
          )
        }))
      }));
      setFeedbackThread({ ...feedbackThread, threadStatus: 'PENDING_RESPONSE' });
      setConfirmingResponse('');
      if (recStudioOpen) {
        setRecStudioOpen(false);
      }
      return;
    }
    if ((!feedbackThread.uuid || quizFeedbackState === 'RETRY_REQUIRED') && mediaContent) {
      mediaFileUploaderServiceRef.current.upload(mediaContent.uuid, mediaContent).subscribe({
        complete: () => {
          if (recStudioOpen) {
            setRecStudioOpen(false);
          }
          feedbackService
            .addFeedback({
              feedbackMessage: { attachment: mediaContent },
              blockUuid: block.uuid,
              journeyUuid: journeyUuid
            })
            .subscribe((pendingThread) => {
              setCourse((c) => ({
                ...c,
                sections: c.sections.map((s) => ({
                  ...s,
                  blocks: s.blocks.map((b) =>
                    block.uuid === b.uuid ? { ...b, quizFeedbackStatus: 'PENDING_RESPONSE' } : b
                  )
                }))
              }));
              setConfirmingResponse('');
              if (isRespond) {
                navigate(HOME);
                return;
              }
              setFeedbackThread(pendingThread);
              if (!isLast) {
                handleOpenNextBlockUpdateQuiz();
              }
            });
        }
      });
    } else {
      const learnerFeedback = {
        ...feedbackThread.learnerFeedback,
        message: answer
      };
      const updatedThread = {
        ...feedbackThread,
        learnerFeedback: { ...learnerFeedback, attachment: mediaContent, uuid: undefined }
      };
      feedbackService
        .addFeedback({
          feedbackMessage: updatedThread.learnerFeedback,
          blockUuid: block.uuid,
          journeyUuid: journeyUuid
        })
        .subscribe((pendingThread) => {
          if (recStudioOpen) {
            setRecStudioOpen(false);
          }
          setConfirmingResponse('');
          setCourse((c) => ({
            ...c,
            sections: c.sections.map((s) => ({
              ...s,
              blocks: s.blocks.map((b) =>
                block.uuid === b.uuid ? { ...b, quizFeedbackStatus: 'PENDING_RESPONSE' } : b
              )
            }))
          }));
          if (isRespond) {
            navigate(HOME);
            return;
          }
          setFeedbackThread(pendingThread);
          if (!isLast) {
            handleOpenNextBlockUpdateQuiz();
          }
        });
    }
  };

  const handleStartOver = useCallback(() => {
    setMediaContent(null);
  }, []);

  const handleOpenRecordingStudio = useCallback(() => {
    setRecStudioOpen(true);
  }, []);

  const handleCloseRecordingStudio = useCallback(() => {
    setRecStudioOpen(false);
  }, []);

  const onStop = useCallback((metadata: RecorderFileMetadata) => {
    if (metadata) {
      const fileToUpload = blobToFile(metadata.blob, 'My recording');
      const mediaFile: MediaFile = {
        uuid: generateMediaFileUuid(),
        file: fileToUpload as File,
        streamingUrl: URL.createObjectURL(fileToUpload),
        fileMetadata: {
          fileSize: fileToUpload.size,
          mediaFileType: metadata.type
        },
        name: fileToUpload.name
      };
      setMediaContent(mediaFile);
    }
    setRecording(false);
  }, []);

  const onCountdownStart = () => {
    setRecording(true);
  };

  const renderQuizAnswerType = () => {
    switch (block?.quiz?.answerType) {
      case 'TEXT':
        return (
          <>
            <QuestionInputStyled
              multiline
              maxRows={20}
              minRows={4}
              placeholder={t('type_response')}
              disabled={
                !!feedbackThread?.learnerFeedback?.message && feedbackThread?.threadStatus !== 'FEEDBACK_REQUIRED'
              }
              value={answer}
              onChange={handleChangeAnswer}
            />
          </>
        );
      case 'VIDEO':
      case 'AUDIO':
        return (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10} md={8}>
                {mediaContent ? (
                  <>
                    <StyledPlayer
                      withFeedback={!!feedbackThread?.threadStatus}
                      onDelete={answerCommitted ? undefined : handleStartOver}
                      playerSx={{ minWidth: '50%', zIndex: 8 }}
                      file={mediaContent}
                    />
                    {!answerCommitted && !mediaContent && (
                      <StyledPlayerControls
                        mediaType={mediaContent.fileMetadata.mediaFileType === 'AUDIO' ? 'AUDIO' : 'VIDEO'}
                        onDone={handleSaveMedia}
                        onStartOver={handleStartOver}
                      />
                    )}
                  </>
                ) : recStudioOpen ? (
                  <>
                    {/* TODO: rework Recorder so we don't have to hack it from outside. Move navigation to inside of recorder and get rid of controlsSx */}
                    {/* TODO: fix navigation prop, right now it's useless */}
                    <StyledRecorder
                      withFeedback={quizFeedbackState === 'RETRY_REQUIRED'}
                      key={block.uuid}
                      noCameraControl
                      mode={block?.quiz?.answerType}
                      sidebarExpanded={sidebarExpanded}
                      scaleUp={true}
                      onStop={onStop}
                      onCountDownStart={onCountdownStart}
                      controlsSx={{ left: 0 }}
                    />
                    {/* TODO: this should be reworked once recorder is reworked as mentioned above */}
                    {!recording && (
                      <THQPrimaryButton
                        sx={{ bottom: 25, left: 40, position: 'absolute' }}
                        onClick={handleCloseRecordingStudio}
                      >
                        {t('cancel')}
                      </THQPrimaryButton>
                    )}
                  </>
                ) : (
                  <QuizRecordingStudio
                    type={block?.quiz?.answerType === 'AUDIO' ? 'audio' : 'video'}
                    onRecordStart={handleOpenRecordingStudio}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <>
      {!!course && !!block && (
        <ContentContainerStyled sx={{ '&&': { paddingBottom: '120px' } }} maxWidth="md">
          <Grid alignItems="center" container justifyContent="center" sx={{ height: '100%' }}>
            <Grid item xs={12}>
              {!loading && (
                <>
                  {feedbackThread?.threadStatus === 'PENDING_RESPONSE' && (
                    <FeedbackMessageContainer review>
                      <FeedbackContent textAlign="center">
                        {t('quiz_feedback_content')}
                      </FeedbackContent>
                    </FeedbackMessageContainer>
                  )}
                  {feedbackThread?.threadStatus === 'FEEDBACK_REQUIRED' && (
                    <FeedbackMessageContainer>
                      <FeedbackHeadline>{t('your_feedback')}:</FeedbackHeadline>
                      <FeedbackContent textAlign="center">{feedbackThread.adminFeedback.message}</FeedbackContent>
                    </FeedbackMessageContainer>
                  )}
                  {quizFeedbackState === 'COMPLETED' && (
                    <FeedbackMessageContainer accepted>
                      <FeedbackContent textAlign="center" accepted>
                        {t('quiz_feedback_response')}
                      </FeedbackContent>
                    </FeedbackMessageContainer>
                  )}
                  {block?.quiz?.question?.itemTextContent && (
                    <TypographyQuizQuestion>{block?.quiz?.question?.itemTextContent}</TypographyQuizQuestion>
                  )}
                  {block?.quiz?.question?.itemMediaContent && (
                    <Grid item sx={{ marginTop: '32px' }}>
                      <Grid container justifyContent={'center'}>
                        <Grid
                          item
                          xs={12}
                          sm={10}
                          md={block?.quiz?.question?.itemMediaContent.fileMetadata?.mediaFileType === 'IMAGE' ? 4 : 6}
                        >
                          {renderMediaContent(block?.quiz?.question?.itemMediaContent)}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {renderQuizAnswerType()}
                  {(((block?.quiz?.answerType === 'VIDEO' || block?.quiz?.answerType === 'AUDIO') &&
                    !answerCommitted &&
                    mediaContent) ||
                    (block?.quiz?.answerType === 'TEXT' && !answerCommitted)) && (
                    <ConfirmAnswerButton
                      disabled={block?.quiz?.answerType === 'TEXT' && !answer}
                      inProgress={confirmingResponse}
                      variant="fancy"
                      onClick={confirmAnswer}
                    >
                      {t('confirm_response')}
                    </ConfirmAnswerButton>
                  )}
                  {!recStudioOpen && (
                    <>
                      <BlockNav
                        disabled={!previewModeOn && !answerCommitted}
                        nextCallback={handleOpenNextBlockUpdateQuiz}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </ContentContainerStyled>
      )}
    </>
  );
};

export default Quiz;
