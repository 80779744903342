import React, { useCallback, useMemo } from 'react';

import { Grid, useTheme } from '@mui/material';
import {
  getFormattedDateString,
  ProgressStatus,
  RoleplayBuilder,
  THQSmallHeadline
} from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import {
  DescriptionStyled,
  THQContentBuilderCardStyled,
  THQSmallHeadlineStyled
} from '@/components/common/contentBuilderList/styles';
import { getButtonText } from '@/components/common/contentBuilderList/utils';
import { DueDateTypography } from '@/components/common/styles';
import { parseDescriptionAddLinks } from '@/components/common/utils/parseTextIntoLink';
import { COURSE_DETAILS_STRING, JOURNEY_STRING, ROLE_PLAY_STRING } from '@/constants/router';
import { LearnerContentBuilder, LearnerCourse } from '@/models/learnerModels';
import { getChipInfo } from '@/utils/courseUtils';
import { useTranslation } from 'react-i18next';

interface ListContentBuilderProps {
  contentBuilder: LearnerContentBuilder;
  returnLink?: string;
}

// TODO: use context to prevent prop drilling
const ContentBuilderItem: React.FC<ListContentBuilderProps> = ({ contentBuilder, returnLink }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const courseProgressPercent = contentBuilder.course?.progressPercentage;
  const progress = contentBuilder.course
    ? contentBuilder.course?.courseStatus
    : contentBuilder?.rolePlay
    ? contentBuilder?.rolePlay?.progressStatus
    : contentBuilder?.journey?.progressStatus;

  // TODO: add type definitions
  const handleOnJourneyClick = useCallback(
    (journey) => {
      const tabToOpen =
        journey.progressStatus === ProgressStatus.COMPLETED ? ActiveTab.COMPLETED : ActiveTab.YOUR_CONTENT;
      navigate(`${JOURNEY_STRING}/${contentBuilder.journey?.uuid}?returnTo=/`, {
        state: tabToOpen
      });
    },
    [contentBuilder?.journey?.uuid, navigate]
  );

  // TODO: I think it's a duplicate, or very similar method exists in another place
  const openCourseDetails = useCallback(
    (course: LearnerCourse) => {
      const tabToOpen = course.courseStatus === ProgressStatus.COMPLETED ? ActiveTab.COMPLETED : ActiveTab.YOUR_CONTENT;
      const flatSectionBlocksUuids = course?.sections?.flatMap(
        (s) =>
          s?.blocks?.find(
            (block) =>
              block.quizFeedbackStatus === 'APPROVER_REJECTED' || block.quizFeedbackStatus === 'FEEDBACK_REQUIRED'
          )?.uuid
      );
      const firstQuizSlideUuid = flatSectionBlocksUuids.find((uuid) => !!uuid);

      let queryParams = '';
      if (returnLink) {
        queryParams = `returnTo=${returnLink}`;
      }
      if (course.journeyUuid) {
        queryParams = `${queryParams}&journeyUuid=${course.journeyUuid}`;
      }
      navigate(
        firstQuizSlideUuid
          ? `${COURSE_DETAILS_STRING}/${course.uuid}/block/${firstQuizSlideUuid}?${queryParams}`
          : `${COURSE_DETAILS_STRING}/${course.uuid}?${queryParams}`,
        { state: tabToOpen }
      );
    },
    [navigate, returnLink]
  );

  const handleAction = useCallback(
    (course: LearnerCourse) => {
      openCourseDetails(course);
    },
    [openCourseDetails]
  );

  const handleOpenRoleplay = useCallback(
    (rp: RoleplayBuilder) => {
      navigate(`${ROLE_PLAY_STRING}?roleplay=${rp?.uuid}`);
    },
    [navigate]
  );

  const parseDescription = (desc) => {
    return parseDescriptionAddLinks(desc);
  };

  const renderAsJourney = useMemo(() => {
    return contentBuilder ? (
      <THQContentBuilderCardStyled
        asFraction
        learnerLayout
        total={contentBuilder.journey?.numberOfSteps}
        passed={contentBuilder.journey?.numberOfCompletedSteps}
        description={
          contentBuilder?.journey?.description ? (
            <DescriptionStyled>{contentBuilder?.journey?.description}</DescriptionStyled>
          ) : undefined
        }
        descriptionPlaceholder={<DescriptionStyled asPlaceholder>{t('no_journey_description')}</DescriptionStyled>}
        subtitle={
          <THQSmallHeadlineStyled onClick={handleOnJourneyClick}>{t('journey_all_capital')}</THQSmallHeadlineStyled>
        }
        footnote={
          <>
            {contentBuilder?.journey?.progressStatus !== ProgressStatus.COMPLETED && (
              <DueDateTypography overdue={contentBuilder?.journey?.dueDate - new Date().valueOf() < 0}>
                {contentBuilder?.journey?.dueDate && (
                  <>
                    {t('due')} {getFormattedDateString({ dueDate: contentBuilder?.journey?.dueDate, t: t, lang: i18n.language })}
                  </>
                )}
              </DueDateTypography>
            )}
          </>
        }
        progressBar={(contentBuilder.journey?.numberOfCompletedSteps / contentBuilder.journey?.numberOfSteps) * 100}
        chipInfo={getChipInfo(progress, theme, t)}
        button={{
          variant:
            contentBuilder.journey?.progressStatus === ProgressStatus.NOT_STARTED ||
            (contentBuilder.journey?.progressStatus === 'DUE' && contentBuilder.journey?.numberOfCompletedSteps === 0)
              ? 'main'
              : 'standard',
          text:
            contentBuilder?.journey?.progressStatus === ProgressStatus.NOT_STARTED ||
            (contentBuilder.journey?.progressStatus === ProgressStatus.DUE &&
              contentBuilder.journey?.numberOfCompletedSteps === 0)
              ? t('start')
              : contentBuilder?.journey?.progressStatus === ProgressStatus.COMPLETED
              ? t('view')
              : t('continue'),
          action: handleOnJourneyClick // TODO: provide type definitions, it has to extend something
        }}
        entity={contentBuilder.journey}
      />
    ) : null;
  }, [contentBuilder, progress, handleOnJourneyClick, theme]);

  const renderAsCourse = useMemo(() => {
    return (
      <THQContentBuilderCardStyled
        learnerLayout
        description={
          contentBuilder?.course?.description ? (
            <DescriptionStyled>{contentBuilder?.course?.description}</DescriptionStyled>
          ) : undefined
        }
        descriptionPlaceholder={<DescriptionStyled asPlaceholder>{t('no_course_description')}</DescriptionStyled>}
        subtitle={<THQSmallHeadline>{t('course_all_capital')}</THQSmallHeadline>}
        progressBar={progress !== ProgressStatus.NOT_STARTED ? courseProgressPercent : undefined}
        chipInfo={getChipInfo(progress, theme, t)}
        button={{
          variant: progress === ProgressStatus.NOT_STARTED ? 'main' : 'standard',
          text: getButtonText(t, progress),
          action: handleAction
        }}
        entity={contentBuilder.course}
      />
    );
  }, [contentBuilder?.course, progress, courseProgressPercent, handleAction, theme]);

  const renderAsRolePlay = useMemo(() => {
    return (
      <THQContentBuilderCardStyled
        learnerLayout
        asFraction
        description={
          contentBuilder?.rolePlay?.description ? (
            <DescriptionStyled>{parseDescription(contentBuilder?.rolePlay?.description)}</DescriptionStyled>
          ) : undefined
        }
        descriptionPlaceholder={<DescriptionStyled asPlaceholder>{t('no_roleplay_description')}</DescriptionStyled>}
        subtitle={<THQSmallHeadline>{t('roleplay_all_capital')}</THQSmallHeadline>}
        total={contentBuilder?.rolePlay?.passRequirement}
        passed={contentBuilder?.rolePlay?.finishedInARow}
        progressBar={
          contentBuilder?.rolePlay?.rolePlayType === 'STRICT'
            ? (contentBuilder?.rolePlay?.finishedInARow / contentBuilder?.rolePlay?.passRequirement) * 100
            : undefined
        }
        chipInfo={getChipInfo(progress, theme, t)}
        button={{
          variant: progress === ProgressStatus.NOT_STARTED ? 'main' : 'standard',
          text: getButtonText(t, progress, t('roleplay')),
          action: handleOpenRoleplay
        }}
        entity={contentBuilder.rolePlay}
      />
    );
  }, [contentBuilder?.rolePlay, progress, handleOpenRoleplay, theme]);

  const renderContentBuilder = useMemo(() => {
    switch (contentBuilder.contentBuilderType) {
      case 'JOURNEY':
        return renderAsJourney;
      case 'ROLE_PLAY':
        return renderAsRolePlay;
      default:
        return renderAsCourse;
    }
  }, [contentBuilder?.contentBuilderType, renderAsCourse, renderAsJourney, renderAsRolePlay]);

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      {renderContentBuilder}
    </Grid>
  );
};

export default ContentBuilderItem;
