import React, { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FeatureFlag, FeaturePermission, ProgressStatus, THQAvatar } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router-dom';

import { ContentBuilderList } from '@/components/common/contentBuilderList/ContentBuilderList';
import { CourseListRootStyled, DashboardSummaryCard, SubsectionHeadline } from '@/components/home/styles';
import { COURSES, FEEDBACK, HOME, JOURNEYS, ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetAllContentBuilders } from '@/hooks/course/useCourseService';
import { useGetAllPendingThreads } from '@/hooks/feedback/useCourseService';
import { useGetAllRoleplays } from '@/hooks/roleplay/useRolePlayService';
import { resolveCourseState } from '@/utils/courseUtils';
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthenticatedUserContext();
  const theme = useTheme();

  const { rolePlays, loading: rolePlaysLoading } = useGetAllRoleplays();
  const { contentBuilders } = useGetAllContentBuilders();
  const [pendingThreads] = useGetAllPendingThreads();

  const [incompleteCourses, setIncompleteCourses] = useState(-1);
  const [incompleteJourneys, setIncompleteJourneys] = useState(-1);
  const [feedbacks, setFeedbacks] = useState(-1);

  useEffect(() => {
    if (contentBuilders) {
      const coursesInc = contentBuilders.reduce(
        (acc, item) =>
          !!item.rolePlay || !!item.journey || resolveCourseState(item.course) === ProgressStatus.COMPLETED
            ? acc
            : ++acc,
        0
      );
      const journeysInc = contentBuilders.reduce(
        (acc, item) =>
          !!item.rolePlay || !!item.course || item.journey.progressStatus === ProgressStatus.COMPLETED ? acc : ++acc,
        0
      );
      setIncompleteCourses(coursesInc);
      setIncompleteJourneys(journeysInc);
    }
  }, [contentBuilders]);

  useEffect(() => {
    if (pendingThreads) {
      setFeedbacks(pendingThreads.length);
    }
  }, [pendingThreads]);

  const navigateToCreateCourse = useCallback(() => {
    navigate(COURSES);
  }, [navigate]);

  const navigateToJourneysPage = useCallback(() => {
    navigate(JOURNEYS);
  }, [navigate]);

  const navigateToRoleplayPage = useCallback(() => {
    navigate(ROLEPLAYS);
  }, [navigate]);

  const navigateToFeedback = useCallback(() => {
    navigate(FEEDBACK);
  }, [navigate]);

  const getRoleplayMessage = useCallback(() => {
    if (rolePlaysLoading) {
      return <Typography fontSize={12}>{t('checking')}</Typography>;
    }
    return rolePlays?.length === 0 ? (
      <Typography fontSize={12}>{t('no_rps')}</Typography>
    ) : (
      <Typography fontSize={12}>
        {t('you_have')} <b>{rolePlays?.length}</b> {rolePlays?.length > 1 ? t('roleplays_lower') : t('roleplay_lower')}.
      </Typography>
    );
  }, [rolePlays?.length, rolePlaysLoading]);

  const getFeedbackMessage = useCallback(() => {
    if (feedbacks < 0) {
      return <Typography fontSize={12}>{t('checking')}</Typography>;
    }
    return pendingThreads?.length ? (
      <Typography fontSize={12}>
        {t('you_have')} <b>{feedbacks}</b> {t('more_practice')}
      </Typography>
    ) : (
      <Typography fontSize={12}>{t('home_no_feedback')}</Typography>
    );
  }, [feedbacks, pendingThreads?.length]);

  const getCoursesMessage = useCallback(() => {
    if (incompleteCourses < 0) {
      return <Typography fontSize={12}>{t('checking')}</Typography>;
    }
    return incompleteCourses === 0 ? (
      <Typography fontSize={12}>{t('home_no_unfinished_courses')}</Typography>
    ) : (
      <Typography fontSize={12}>
        {t('you_have')} <b>{incompleteCourses}</b> {incompleteCourses === 1 ? t('course_lower') : t('courses_lower')} {t('to_complete')}.
      </Typography>
    );
  }, [incompleteCourses]);

  const getJourneyMessage = useCallback(() => {
    if (incompleteJourneys < 0) {
      return <Typography fontSize={12}>{t('checking')}</Typography>;
    }
    return incompleteJourneys === 0 ? (
      <Typography fontSize={12}>{t('home_no_unfinished_journeys')}.</Typography>
    ) : (
      <Typography fontSize={12}>
        {t('you_have')} <b>{incompleteJourneys}</b> {incompleteJourneys === 1 ? t('journey_lower') : t('journeys_lower')} {t('to_complete')}
      </Typography>
    );
  }, [incompleteJourneys]);

  return (
    <CourseListRootStyled>
      <Grid container>
        <Grid xs={12} sx={{ marginBottom: '56px' }} item>
          <Typography variant="h3">{t('welcome')}{user?.firstName ? `, ${user?.firstName}` : ''} 👋</Typography>
        </Grid>
        <Grid xs={12} sx={{ marginBottom: '24px' }} item>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <SubsectionHeadline variant="h4">{t('today_tasks')}</SubsectionHeadline>
            </Grid>
            <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
              <Grid xs={12} sm={12} md={6} item>
                <DashboardSummaryCard
                  description={getFeedbackMessage()}
                  icon={
                    <THQAvatar backgroundColor={theme.palette.primary.main}>
                      <span style={{ fontSize: '14px', fontWeight: '700' }}>
                        {pendingThreads ? '' + pendingThreads?.length : '0'}
                      </span>
                    </THQAvatar>
                  }
                  orientation="horizontal"
                  title={
                    <Typography fontSize={12} fontWeight={600}>
                      {t('feedback')}
                    </Typography>
                  }
                  onClick={navigateToFeedback}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} item>
                <DashboardSummaryCard
                  description={getCoursesMessage()}
                  icon={
                    <THQAvatar backgroundColor={theme.palette.primary.main}>
                      <span style={{ fontSize: '14px', fontWeight: '700' }}>
                        {incompleteCourses > 0 ? '' + incompleteCourses : '0'}
                      </span>
                    </THQAvatar>
                  }
                  orientation="horizontal"
                  title={
                    <Typography fontSize={12} fontWeight={600}>
                      {t('courses')}
                    </Typography>
                  }
                  onClick={navigateToCreateCourse}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]}>
              <Grid xs={12} sm={12} md={6} item>
                <DashboardSummaryCard
                  description={getRoleplayMessage()}
                  icon={
                    <THQAvatar backgroundColor={theme.palette.primary.main}>
                      <span style={{ fontSize: '14px', fontWeight: '700' }}>
                        {rolePlays?.length > 0 ? '' + rolePlays?.length : '0'}
                      </span>
                    </THQAvatar>
                  }
                  orientation="horizontal"
                  title={
                    <Typography fontSize={12} fontWeight={600}>
                      {t('roleplays')}
                    </Typography>
                  }
                  onClick={navigateToRoleplayPage}
                />
              </Grid>
            </FeatureFlag>
            <FeatureFlag featurePermissions={[FeaturePermission.JOURNEY]}>
              <Grid xs={12} sm={12} md={6} item>
                <DashboardSummaryCard
                  description={getJourneyMessage()}
                  icon={
                    <THQAvatar backgroundColor={theme.palette.primary.main}>
                      <span style={{ fontSize: '14px', fontWeight: '700' }}>
                        {incompleteJourneys > 0 ? '' + incompleteJourneys : '0'}
                      </span>
                    </THQAvatar>
                  }
                  orientation="horizontal"
                  title={
                    <Typography fontSize={12} fontWeight={600}>
                      {t('journeys')}
                    </Typography>
                  }
                  onClick={navigateToJourneysPage}
                />
              </Grid>
            </FeatureFlag>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid container>
            <Grid xs={12} item>
              <SubsectionHeadline variant="h4">{t('pick_up_where_left')}</SubsectionHeadline>
            </Grid>
            <Grid xs={12} item>
              <ContentBuilderList contentBuilders={contentBuilders} returnLink={HOME} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CourseListRootStyled>
  );
};

export default Home;
