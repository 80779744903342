import React, { useCallback } from 'react';

import { Box, Grid, Typography, useTheme } from '@mui/material';
import { itemTypeToStr, MilestoneStatus, THQPrimaryButton, useTHQSnackbar } from '@trainhq/trainhq-client-core';
import { mergeMap } from 'rxjs';

import ItemActions from '@/components/journey/item/actions/ItemActions';
import { actionsHeight } from '@/components/journey/item/actions/styles';
import {
  ChipStyled,
  InfoBoxStyled,
  ItemTypeTextStyled,
  SuccessBoxStyled,
  WarningBoxStyled
} from '@/components/journey/item/milestone/styles';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';
import { useJourneyService } from '@/hooks/journey/useJourneyService';
import { useMilestoneService } from '@/hooks/milestone/useMilestoneService';
import { getChipInfo } from '@/utils/courseUtils';
import { useTranslation } from 'react-i18next';

const MilestonePanelItem: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const snacbkar = useTHQSnackbar();
  const milestoneService = useMilestoneService();
  const journeyService = useJourneyService();
  const { journey, selectedItem } = useJourneyContext();
  const { setSelectedItem, setOpenItemDrawer, setJourney } = useJourneySetters();

  const certifierName = selectedItem?.milestone?.certifier
    ? `${selectedItem?.milestone?.certifier?.firstName} ${selectedItem?.milestone?.certifier?.lastName}`
    : '';
  const milestoneCompleted =
    selectedItem?.milestone?.milestoneStatus === MilestoneStatus.LEARNER_COMPLETED ||
    selectedItem?.milestone?.milestoneStatus === MilestoneStatus.APPROVED;
  const chipValue = getChipInfo(selectedItem?.milestone?.milestoneStatus, theme, t);

  const handleOnCancel = useCallback(() => {
    setSelectedItem(undefined);
    setOpenItemDrawer(false);
  }, [setOpenItemDrawer, setSelectedItem]);

  const handleCompleteMilestone = useCallback(() => {
    milestoneService
      .completeMilestone(selectedItem?.milestone, journey.uuid)
      .pipe(
        mergeMap((milestone) => {
          setSelectedItem({ ...selectedItem, milestone });
          return journeyService.getJourney(journey.uuid);
        })
      )
      .subscribe({
        next: (journey) => {
          snacbkar('Milestone resolved');
          setJourney(journey);
        }
      });
  }, [journey?.uuid, journeyService, milestoneService, selectedItem, setJourney, setSelectedItem, snacbkar]);

  return (
    <>
      <Box sx={{ height: `calc(100% - ${actionsHeight}px)`, overflowY: 'auto', position: 'relative' }}>
        <Grid container sx={{ padding: '32px' }}>
          <Grid item sx={{ marginBottom: '29px' }} xs={12}>
            <Grid container columnSpacing={3}>
              <Grid item>
                <ItemTypeTextStyled>{itemTypeToStr(selectedItem.journeyItemType).toUpperCase()}</ItemTypeTextStyled>
              </Grid>
              <Grid item>
                <ChipStyled
                  background={chipValue?.background}
                  foreground={chipValue?.foreground}
                  label={chipValue?.text}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Typography fontSize={24} fontWeight={700}>
              {selectedItem?.milestone?.name}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {t('task')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '32px' }} xs={12}>
            <Typography fontSize={14}>{selectedItem?.milestone?.question}</Typography>
          </Grid>
          {/*<Grid item sx={{ marginBottom: '34px' }} xs={12}>*/}
          {/*  <Grid alignItems="center" container columnSpacing={1}>*/}
          {/*    <Grid item xs="auto">*/}
          {/*      <IconWrapperStyled>*/}
          {/*        <TimeIcon style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />*/}
          {/*      </IconWrapperStyled>*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs>*/}
          {/*      <Typography fontSize={14}>*/}
          {/*        Due{' '}*/}
          {/*        {getFormattedDateString({*/}
          {/*          dueDate: selectedItem?.milestone?.dueDate,*/}
          {/*          withAdjective: true*/}
          {/*        })}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          {selectedItem?.milestone?.milestoneStatus === MilestoneStatus.REJECTED ? (
            <Grid item xs={12}>
              <WarningBoxStyled>
                <Typography fontSize={14}>
                  {t('step_rejected')}{certifierName ? ` ${t('by_lower')} ${certifierName}` : ''}
                </Typography>
              </WarningBoxStyled>
            </Grid>
          ) : selectedItem?.milestone?.milestoneStatus === MilestoneStatus.APPROVED ? (
            <Grid item xs={12}>
              <SuccessBoxStyled>
                <Typography fontSize={14}>
                  {t('step_approved')}{certifierName ? ` ${t('by_lower')} ${certifierName}` : ''}
                </Typography>
              </SuccessBoxStyled>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InfoBoxStyled>
                <Typography fontSize={14}>
                  {t('step_needs_approved')}{certifierName ? ` ${t('by_lower')} ${certifierName}` : ''}
                </Typography>
              </InfoBoxStyled>
            </Grid>
          )}
        </Grid>
      </Box>
      <ItemActions>
        <Grid container columnSpacing={1} justifyContent="right">
          <Grid item>
            <THQPrimaryButton onClick={handleOnCancel}>{t('cancel')}</THQPrimaryButton>
          </Grid>
          <Grid item>
            <THQPrimaryButton variant="main" disabled={milestoneCompleted} onClick={handleCompleteMilestone}>
              {t('complete_milestone')}
            </THQPrimaryButton>
          </Grid>
        </Grid>
      </ItemActions>
    </>
  );
};

export default MilestonePanelItem;
