import React from 'react';

import { Grid, Typography } from '@mui/material';
import { ChallengeType, DetailsCardPercentages, DetailsCardPercentagesProps } from '@trainhq/trainhq-client-core';

import CardContainer from '@/components/insights/role-play/common/cards/CardContainer';
import { ChipStyled } from '@/components/insights/role-play/details/naturalDetailsCard/styles';
import { useTranslation } from 'react-i18next';

interface NaturalDetailsCardProps extends DetailsCardPercentagesProps {
  sectionName: string;
  title: string;
  type: ChallengeType;
}

const NaturalDetailsCard: React.FC<NaturalDetailsCardProps> = ({ sectionName, title, type, ...props }) => {
  const { t } = useTranslation();
  return (
    <CardContainer>
      <Grid container>
        <Grid item xs>
          <Typography fontSize={16} fontWeight={700} sx={{ marginBottom: '4px' }}>
            {title}
          </Typography>
          <Typography color="common.silver" fontSize={16} sx={{ minHeight: '28px' }}>
            {t(sectionName)}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <ChipStyled type={type} label={type === ChallengeType.GOAL ? t('goal') : t('objection')} />
        </Grid>
      </Grid>
      <DetailsCardPercentages {...props} />
    </CardContainer>
  );
};

export default NaturalDetailsCard;
