import React, { createContext, useCallback, useContext, useState } from 'react';

import { Typography } from '@mui/material';
import {
  THQDialog,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  THQPrimaryButton
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

type CallTimeoutDialogContext = (onRestartCallback: () => void) => void;
const context = createContext<CallTimeoutDialogContext>(null);
export const useCallTimeoutDialog = () => useContext(context);

interface CallTimeoutDialogProps {
  children?: React.ReactNode;
  disableSubmit?: boolean;
  onRestart(callback?: () => void): void;
  onSubmit(): void;
}

const CallTimeoutDialog: React.FC<CallTimeoutDialogProps> = ({ children, disableSubmit, onRestart, onSubmit }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [onRestartCallback, setOnRestartCallback] = useState<() => void>();

  const handleOnRestart = useCallback(() => {
    setOpen(false);
    onRestart(onRestartCallback);
  }, [onRestart, onRestartCallback]);

  const contextValue = useCallback<CallTimeoutDialogContext>((onRestartCallback: () => void) => {
    setOpen(true);
    setOnRestartCallback(() => onRestartCallback);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} showXButton={false}>
        <THQDialogTitle>{t('rp_call_still_there')} 👀</THQDialogTitle>
        <THQDialogContent>
          <Typography>
            {t('rp_call_timeout_message')}
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <THQPrimaryButton onClick={handleOnRestart}>{t('common.rp_validation_start_top')}</THQPrimaryButton>
          <THQPrimaryButton disabled={disableSubmit} variant="main" onClick={onSubmit}>
            {t('rp_submit')}
          </THQPrimaryButton>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default CallTimeoutDialog;
