import React from 'react';

import { Grid } from '@mui/material';
import { LearnerFeedbackThread, THQAvatar, THQBody, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router-dom';

import {
  CourseNameLink,
  DashboardSummaryContainer,
  DashboardSummarySubtitle,
  DashboardSummaryTitle
} from '@/components/feedback/feedbackCard/styles';
import { BLOCK_STRING, COURSE_DETAILS_STRING } from '@/constants/router';
import { useTranslation } from 'react-i18next';

interface FeedbackCardProps {
  title: string | React.ReactElement;
  courseTitle: string | React.ReactElement;
  description: string | React.ReactElement;
  avatarSrc?: string;
  avatarColorGenString?: string;
  feedback?: string;
  avatarText: string;
  feedbackObject?: LearnerFeedbackThread;
}

// TODO: returnTo - check if it can be solved in a nicer more readable and friendly way
export const FeedbackCard: React.FC<FeedbackCardProps> = ({
  feedbackObject,
  avatarSrc,
  avatarText,
  avatarColorGenString,
  title,
  courseTitle,
  description,
  feedback,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openCourse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(
      `${COURSE_DETAILS_STRING}/${feedbackObject.courseUuid}?returnTo=/feedback${
        feedbackObject?.journeyUuid ? '&journeyUuid=' + feedbackObject.journeyUuid : ''
      }`
    );
  };

  const goToBlockWithFeedback = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO: check with Pavke if we can replace slideUuid with blockUuid for consistency reasons
    navigate(
      `${COURSE_DETAILS_STRING}/${feedbackObject.courseUuid}${BLOCK_STRING}/${feedbackObject.slideUuid}${
        feedbackObject?.journeyUuid
          ? `?journeyUuid=${feedbackObject.journeyUuid}&returnTo=/feedback`
          : '?returnTo=/feedback'
      }`
    );
  };

  return (
    <DashboardSummaryContainer {...rest}>
      <CourseNameLink onClick={openCourse}>{courseTitle}</CourseNameLink>
      <Grid sx={{ flexWrap: 'nowrap' }} alignItems="center" container spacing={2}>
        <Grid item xs={'auto'}>
          {avatarSrc ? (
            <THQAvatar src={avatarSrc} />
          ) : (
            <THQAvatar colorRandomizeString={`${avatarColorGenString}`}>{avatarText}</THQAvatar>
          )}
        </Grid>
        <Grid sx={{ paddingLeft: '12px' }} item xs={true}>
          <DashboardSummaryTitle>{title}</DashboardSummaryTitle>
          <DashboardSummarySubtitle>{description}</DashboardSummarySubtitle>
        </Grid>
      </Grid>
      <Grid sx={{ paddingTop: '30px' }} alignItems={'center'} container>
        <THQBody>{feedback}</THQBody>
      </Grid>
      <Grid sx={{ marginTop: '24px', marginBottom: '0' }} container>
        <THQPrimaryButton onClick={goToBlockWithFeedback}>{t('retry')}</THQPrimaryButton>
      </Grid>
    </DashboardSummaryContainer>
  );
};
