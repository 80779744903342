import {
  BackgroundTaskExecutorState,
  Inject,
  Injectable,
  Pageable,
  PageRequest,
  pollingRequest,
  RoleplayBuilder,
  RoleplayExecutionConfig,
  RoleplayLogAction,
  RoleplayMessage,
  RolePlayModePhase,
  RoleplayUserSummary
} from '@trainhq/trainhq-client-core';
import { mergeMap, Observable, takeLast } from 'rxjs';

import { BackgroundVerificationTaskResult, RolePlaySessionDto } from '@/models/learnerModels';
import { RoleplayRepository } from '@/services/repositoryData/roleplayRepository';

export interface RolePlayService {
  getRoleplay(uuid: string, journeyUuid?: string): Observable<RoleplayBuilder>;
  getRoleplaysPage(pageRequest: PageRequest): Observable<Pageable<RoleplayBuilder>>;
  createRolePlaySession(roleplayUuid?: string, journeyUuid?: string): Observable<RolePlaySessionDto>;
  getStt(sessionUuid: string): Observable<RoleplayExecutionConfig>;
  init(sessionUuid: string): Observable<RoleplayMessage>;
  logAction(payload: RoleplayLogAction): Observable<any>;
  refreshRoleplaySummary(roleplay?: string, journeyUuid?: string): Observable<RoleplayUserSummary>;
  requestAzureToken(): Observable<any>;
  respond(
    payload: {
      sessionUuid: string;
      covered_steps: number[];
      step: number;
      company?: string;
      learner_input?: string;
      fail_occurred: boolean;
    },
    apiV2?: boolean
  ): Observable<any>;
  submitNaturalValidation(payload: any): Observable<BackgroundVerificationTaskResult>;
  setRolePlayPhase(payload: {
    roleplayUuid: string;
    journeyUuid: string;
    rolePlayModePhase: RolePlayModePhase;
  }): Observable<any>;
}

@Injectable('rolePlayService')
export class RolePlayServiceImpl implements RolePlayService {
  @Inject('roleplayRepository')
  private roleplayRepository: RoleplayRepository;

  createRolePlaySession(roleplayUuid?: string, journeyUuid?: string): Observable<RolePlaySessionDto> {
    return this.roleplayRepository.createRolePlaySession(roleplayUuid, journeyUuid);
  }

  getRoleplay(uuid: string): Observable<RoleplayBuilder> {
    return this.roleplayRepository.get(uuid);
  }

  getRoleplaysPage: (pageRequest: PageRequest) => Observable<Pageable<RoleplayBuilder>> = (
    pageRequest: PageRequest
  ) => {
    return this.roleplayRepository.page(pageRequest);
  };

  getStt(sessionUuid: string): Observable<RoleplayExecutionConfig> {
    return this.roleplayRepository.getStt(sessionUuid);
  }

  init(sessionUuid: string): Observable<RoleplayMessage> {
    return this.roleplayRepository.init(sessionUuid);
  }

  logAction(payload: RoleplayLogAction): Observable<any> {
    return this.roleplayRepository.logAction(payload);
  }

  page: (pageRequest: PageRequest) => Observable<Pageable<RoleplayBuilder>> = (pageRequest: PageRequest) => {
    return this.roleplayRepository.page(pageRequest);
  };

  refreshRoleplaySummary(roleplay?: string, journeyUuid?: string): Observable<RoleplayUserSummary> {
    return this.roleplayRepository.refreshRoleplaySummary(roleplay, journeyUuid);
  }

  requestAzureToken(): Observable<any> {
    return this.roleplayRepository.requestAzureToken();
  }

  respond(
    payload: {
      sessionUuid: string;
      covered_steps: number[];
      step: number;
      history: any[];
      company?: string;
      learner_input: string;
      fail_occurred: boolean;
    },
    apiV2?: boolean
  ): Observable<RoleplayMessage> {
    return this.roleplayRepository.respond(payload, apiV2);
  }

  submitNaturalValidation(payload: any): Observable<BackgroundVerificationTaskResult> {
    return this.roleplayRepository.submitNaturalValidation(payload).pipe(
      mergeMap((bgTask) => {
        return pollingRequest(
          this.roleplayRepository.getBackgroundTask(bgTask.uuid),
          (bgTaskResult) =>
            bgTaskResult.backgroundExecutorTaskState === BackgroundTaskExecutorState.SUCCESS ||
            bgTaskResult.backgroundExecutorTaskState === BackgroundTaskExecutorState.ERROR,
          4000
        ).pipe(takeLast(1));
      })
    );
  }

  setRolePlayPhase = (payload: {
    roleplayUuid: string;
    journeyUuid: string;
    rolePlayModePhase: RolePlayModePhase;
  }): Observable<any> => {
    return this.roleplayRepository.setRolePlayPhase(payload);
  };
}
