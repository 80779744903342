import { ML_URL } from '@/hooks/config/useAppConfig';

export const USER_LEARNER = 'learner/user';
export const GET_CURRENT_USER = `${USER_LEARNER}/get`;
export const USER_MANAGER_VERIFY = `${USER_LEARNER}/verify`;
export const USER_LEARNER_TOKEN_AUTH = `${USER_LEARNER}/tokenAuth`;

export const USER_LEARNER_RESET_PASSWORD = `${USER_LEARNER}/resetPassword`;
export const USER_LEARNER_FORGOT_LINK = `${USER_LEARNER}/forgotPassword`;
export const USER_LEARNER_CHANGE_PASSWORD = `${USER_LEARNER}/changePassword`;
export const USER_LEARNER_RESEND_VERIFICATION_LINK = `${USER_LEARNER}/resendVerification`;
export const GET_PASSWORD_POLICY = `${USER_LEARNER}/policyConfig`;
export const GET_PASSWORD_POLICY_FOR_VERIFICATION = `${USER_LEARNER}/setup/policyConfig`;

// course
export const LEARNER_COURSE = 'learner/companyCourse';
export const GET_COURSE = `${LEARNER_COURSE}/get`;
export const PREVIEW_COURSE = `${LEARNER_COURSE}/preview`;
export const GET_ALL_COURSES = `${LEARNER_COURSE}/userCourses`;
export const COURSE_SET_PROGRESS = `${LEARNER_COURSE}/addCurrentProgress`;
export const GET_ALL_CONTENT_BUILDERS = `${LEARNER_COURSE}/userContent`;
export const GET_COURSE_PAGE = `${LEARNER_COURSE}/page`;
export const ENROLL_TO_COURSE = `${LEARNER_COURSE}/requestEnrollment`;

export const GET_COURSE_BLOCK = `${LEARNER_COURSE}/block/get`;

// feedback
const BASE_FEEDBACK_ROUTE = 'learner/course/quizFeedback';
export const COURSE_PENDING_FEEDBACK_THREADS = `${BASE_FEEDBACK_ROUTE}/pendingThreads`;
export const ADD_LEARNER_FEEDBACK = `${BASE_FEEDBACK_ROUTE}/add`;
export const BLOCK_PENDING_FEEDBACK_THREAD = `${BASE_FEEDBACK_ROUTE}/blockPendingThread`;

// Multiple choice
const BASE_MULTI_CHOICE_ANSWER_ROUTE = 'learner/courseBuilder/multipleChoiceAnswer';
export const ADD_MULTI_CHOICE_ANSWER = `${BASE_MULTI_CHOICE_ANSWER_ROUTE}/addAnswer`;

//SCORM
export const SAVE_SCORM_STATE = `learner/courseBuilder/scorm/progress/set`;

//RP
export const RP_REQUEST_AZURE_TOKEN = 'api/v1/ml/azure-token';

export const RP_CREATE_SESSION = 'learner/rolePlay/createSession';

export const RP_INIT = 'api/v1/ml/init';
export const RP_INFERENCE = 'api/v1/ml/inference';
export const RP_STT = 'api/v1/ml/stt';
export const RP_USER_SUMMARY = 'api/v1/ml/userSummary';
export const RP_LOG_ACTION = 'api/v1/event/logAction';
export const SUBMIT_NATURAL = 'api/v1/ml/submit';
export const LEGACY_ROLEPLAY = 'learner/rolePlay';
export const LEGACY_GET_ALL_ROLEPLAYS = `${LEGACY_ROLEPLAY}/userRolePlays`;
export const LEGACY_GET_ROLEPLAY = `${LEGACY_ROLEPLAY}/get`;
export const RP_SET_PHASE = `api/v1/ml/setRolePlayPhase`;

// Journey
export const JOURNEY = 'learner/journey';
export const JOURNEY_GET = `${JOURNEY}/get`;
export const JOURNEY_PAGE = `${JOURNEY}/page`;

// Library
export const CONTENT_LIBRARY = 'learner/company/contentLibrary';
export const CONTENT_ITEM_GET = `${CONTENT_LIBRARY}/get`;
export const CONTENT_ITEM_PAGE = `${CONTENT_LIBRARY}/page`;
export const CONTENT_ITEM_GET_ALL = `${CONTENT_LIBRARY}/getAll`;

// Milestone
export const MILESTONE = 'learner/milestone';
export const MILESTONE_COMPLETE = `${MILESTONE}/complete`;

// Web socket topics
export const COURSE_TOPIC = 'learner/course';
export const RP_SESSION_TOPIC = 'learner/rpTimeTracking';

// StrictRolePlay analytics
export const RP_ANALYTICS_BASE = 'learner/company/rolePlay/analytics';
export const GET_ERRORS_PER_ATTEMPT_DATA_STRICT = `${RP_ANALYTICS_BASE}/strict/errorsPerAttempts`;
export const GET_ERRORS_PER_PAIR_DATA = `${RP_ANALYTICS_BASE}/strict/errorsPerPairs`;
export const GET_ERRORS_PER_COUNT_DATA_STRICT = `${RP_ANALYTICS_BASE}/strict/eventsCountStats`;
export const RP_EXPORT_LEADERBOARD = `${RP_ANALYTICS_BASE}/export/leaderboard`;
export const RP_EXPORT_USER_VALIDATION_REPORTS = `${RP_ANALYTICS_BASE}/natural/export/userValidationReports`;
export const RP_EXPORT_LATEST_ACTIVITY = `${RP_ANALYTICS_BASE}/export/latestActivityData`;
export const RP_EXPORT_LEARNERS = `${RP_ANALYTICS_BASE}/export/rolePlayLearners`;
export const GET_LOGS_ANALYTICS = `${RP_ANALYTICS_BASE}/strict/actionAnalytics`;

export const GET_ERRORS_PER_COUNT_DATA_NATURAL = `${RP_ANALYTICS_BASE}/natural/reports/eventsCountStats`;
export const GET_ERRORS_PER_ATTEMPT_DATA_NATURAL = `${RP_ANALYTICS_BASE}/natural/errorsPerAttempts`;
export const GET_NRP_REPORTCARDS = `${RP_ANALYTICS_BASE}/natural/userValidationReports`;
export const ADD_NRP_SELF_ASSESSMENT = `${RP_ANALYTICS_BASE}/natural/addAssessment`;
export const GET_NRP_REPORT = `${RP_ANALYTICS_BASE}/natural/report`;
export const GET_NRP_DETAILS = `${RP_ANALYTICS_BASE}/natural/challengeDetails`;
export const GET_NRP_SECTIONS = `${RP_ANALYTICS_BASE}/natural/rolePlaySections`;
export const CHALLENGE_SCORES = `${RP_ANALYTICS_BASE}/natural/challengeScores`;
export const EVENTS_COUNT_STATS_NATURAL = `${RP_ANALYTICS_BASE}/natural/challenges/eventsCountStats`;
export const RP_INSIGHTS_USER_STATUS = `${RP_ANALYTICS_BASE}/userStatus`;

// TTS
export const TEXT_TO_SPEECH = `${ML_URL}api/v1/ml/textToSpeech`;

// CALL INTELLIGENCE thq server
export const CALL_INTELLIGENCE_ROOT = 'learner/company/callIntelligence';
export const CALL_INTELLIGENCE = `${CALL_INTELLIGENCE_ROOT}/campaign`;
export const CALL_INTELLIGENCE_GET = `${CALL_INTELLIGENCE}/get`;
export const CALL_INTELLIGENCE_PAGE = `${CALL_INTELLIGENCE}/page`;
export const CALL_INTELLIGENCE_AVAILABLE_CALL_TYPES = `${CALL_INTELLIGENCE_ROOT}/calls/callTypes`;
export const CALL_INTELLIGENCE_CALLS = `${CALL_INTELLIGENCE_ROOT}/calls/page`;

// Translations thq server
export const GET_TRANSLATIONS = `api/translations/learner`;
export const GET_AVAILABLE_LANGUAGES = `api/translations/public/languages`;
