import { createContext, useContext } from 'react';

import { RoleplayBuilder } from '@trainhq/trainhq-client-core';

interface SelectedRPInsightsTabContextProps {
  selectedRolePlay?: RoleplayBuilder;
  tabValue?: number;
}

export const SelectedRPInsightsTabContext = createContext<SelectedRPInsightsTabContextProps>(null);
export const useSelectedRPInsightsTabContext = () => useContext(SelectedRPInsightsTabContext);
