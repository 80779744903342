import React, { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { itemTypeToStr, ProgressStatus, THQAvatar, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { ReactComponent as Info } from '@/assets/icons/info-empty.svg';
import { ReactComponent as Medal } from '@/assets/icons/medal.svg';
import { PanelIconWrapperStyled } from '@/components/journey/common/styles';
import ItemActions from '@/components/journey/item/actions/ItemActions';
import { actionsHeight } from '@/components/journey/item/actions/styles';
import { ItemTypeTextStyled } from '@/components/journey/item/milestone/styles';
import { JOURNEY_STRING, ROLEPLAY_DETAILS } from '@/constants/router';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';
import { useTranslation } from 'react-i18next';

const RolePlayPanelItem: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedItem, journey } = useJourneyContext();
  const { setSelectedItem, setOpenItemDrawer } = useJourneySetters();

  const ownersFirstName = selectedItem?.rolePlay?.owner?.firstName;
  const ownersLastName = selectedItem?.rolePlay?.owner?.lastName;
  const rolePlayCompleted = selectedItem?.rolePlay?.progressStatus === ProgressStatus.COMPLETED;
  const rolePlayInProgress = selectedItem?.rolePlay?.progressStatus === ProgressStatus.IN_PROGRESS;

  const handleOnCancel = useCallback(() => {
    setSelectedItem(undefined);
    setOpenItemDrawer(false);
  }, [setOpenItemDrawer, setSelectedItem]);

  const handleStartCourse = useCallback(() => {
    navigate(
      `${ROLEPLAY_DETAILS}?roleplay=${selectedItem?.rolePlay?.uuid}&journey=${journey?.uuid}&returnTo=${JOURNEY_STRING}/${journey?.uuid}`
    );
  }, [journey?.uuid, navigate, selectedItem?.rolePlay?.uuid]);

  return (
    <>
      <Box sx={{ height: `calc(100% - ${actionsHeight}px)`, overflowY: 'auto', position: 'relative' }}>
        <Grid container sx={{ height: '100%', overflowY: 'auto', padding: '32px' }}>
          <Grid item sx={{ marginBottom: '29px' }} xs={12}>
            <ItemTypeTextStyled>{itemTypeToStr(selectedItem?.journeyItemType).toUpperCase()}</ItemTypeTextStyled>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Typography fontSize={24} fontWeight={700}>
              {selectedItem?.rolePlay?.name}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {t('information')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Grid alignItems="center" container columnSpacing={1}>
              <Grid item>
                <PanelIconWrapperStyled>
                  <Medal />
                </PanelIconWrapperStyled>
              </Grid>
              <Grid item>
                <Typography>
                  {selectedItem?.rolePlay?.finishedInARow}/{selectedItem?.rolePlay?.passRequirement}{' '}
                  {t('successful_completions')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontWeight={700}>{t('requirements')}</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Grid alignItems="center" flexWrap="nowrap" container columnSpacing={1}>
              <Grid item>
                <PanelIconWrapperStyled>
                  <Info />
                </PanelIconWrapperStyled>
              </Grid>
              <Grid item>
                <Typography>
                  {t('rp_have_to_complete')}{' '}
                  <Typography component="span" fontWeight={700}>
                    {selectedItem?.rolePlay?.passRequirement ?? 1}{' '}
                    {selectedItem?.rolePlay?.passRequirement && selectedItem?.rolePlay?.passRequirement > 1
                      ? t('times_in_a_row')
                      : t('time')}
                  </Typography>{' '}
                  without errors.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Typography fontWeight={700}>{t('roleplay_owner')}</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Grid alignItems="center" container columnSpacing={1}>
              <Grid item>
                <THQAvatar colorRandomizeString={`${ownersFirstName}${ownersLastName}`} sx={{ fontSize: 14 }}>
                  {ownersFirstName?.[0]}
                  {ownersLastName?.[0]}
                </THQAvatar>
              </Grid>
              <Grid item>
                <Typography>
                  {ownersFirstName} {ownersLastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {t('overview')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Typography fontWeight={700}>{t('backstory')}</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Typography>{selectedItem?.rolePlay?.description}</Typography>
          </Grid>
        </Grid>
      </Box>
      <ItemActions>
        <Grid container columnSpacing={1} justifyContent="right">
          <Grid item>
            <THQPrimaryButton onClick={handleOnCancel}>{t('cancel')}</THQPrimaryButton>
          </Grid>
          <Grid item>
            <THQPrimaryButton variant="main" onClick={handleStartCourse}>
              {rolePlayCompleted ? 'View' : rolePlayInProgress ? t('continue') : t('start')}
            </THQPrimaryButton>
          </Grid>
        </Grid>
      </ItemActions>
    </>
  );
};

export default RolePlayPanelItem;
