import React, { useMemo } from 'react';

import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { THQAppBar, THQMainPageContainer } from '@trainhq/trainhq-client-core';

import JourneyList from '@/components/journey/list/JourneyList';
import useSetAppBar from '@/layout/useSetAppBar';
import { useTranslation } from 'react-i18next';

const JourneyListLayout: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const appBar = useMemo(() => (isMobile ? <THQAppBar text={t('journeys')} /> : null), [isMobile]);

  useSetAppBar(appBar);

  return (
    <THQMainPageContainer>
      {!isMobile && (
        <Typography fontSize={24} fontWeight={600}>
          {t('journeys')}
        </Typography>
      )}
      <JourneyList />
    </THQMainPageContainer>
  );
};

export default JourneyListLayout;
