import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { THQTab, THQTabContainer } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import JourneyListTabPanel from '@/components/journey/list/JourneyListTabPanel';
import { useGetJourneysPage } from '@/hooks/journey/useJourneyService';
import { useTranslation } from 'react-i18next';

const JourneyList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab');
  const { loadedItems, loading } = useGetJourneysPage({ page: 0, size: 500 });

  const [tabValue, setTabValue] = useState(activeTab === 'completed' ? 1 : 0);

  const unfinishedJourneys = useMemo(
    () => loadedItems?.filter((journey) => journey?.numberOfSteps !== journey?.numberOfCompletedSteps),
    [loadedItems]
  );

  const completedJourneys = useMemo(
    () => loadedItems?.filter((journey) => journey?.numberOfSteps === journey?.numberOfCompletedSteps),
    [loadedItems]
  );

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
      navigate(newValue === 0 ? '?activeTab=your-journeys' : '?activeTab=completed');
    },
    [navigate]
  );

  return (
    <>
      <Box sx={(theme) => ({ borderBottom: 1, borderColor: theme.palette.common.pearl })}>
        <THQTabContainer value={tabValue} onChange={handleChangeTab}>
          <THQTab tabIndex={0} sx={{ marginBottom: '20px', fontWeight: 700 }} label={t('your_journeys')} />
          <THQTab tabIndex={1} sx={{ marginBottom: '20px', fontWeight: 700 }} label={t('completed')} />
        </THQTabContainer>
      </Box>
      <JourneyListTabPanel journeys={unfinishedJourneys} index={0} loading={loading} tabValue={tabValue} />
      <JourneyListTabPanel journeys={completedJourneys} index={1} loading={loading} tabValue={tabValue} />
    </>
  );
};

export default JourneyList;
