import {
  BackgroundTask,
  BaseCloudService,
  RoleplayBuilder,
  RoleplayExecutionConfig,
  RoleplayLogAction,
  RoleplayMessage,
  RolePlayModePhase,
  RoleplayUserSummary
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  LEGACY_GET_ALL_ROLEPLAYS,
  LEGACY_GET_ROLEPLAY,
  RP_CREATE_SESSION,
  RP_INFERENCE,
  RP_INIT,
  RP_LOG_ACTION,
  RP_REQUEST_AZURE_TOKEN,
  RP_SET_PHASE,
  RP_STT,
  RP_USER_SUMMARY,
  SUBMIT_NATURAL
} from '@/constants/api';
import { ML_URL, ML_URL_V2, ROOT_URL } from '@/hooks/config/useAppConfig';
import { BackgroundVerificationTaskResult, RolePlaySessionDto } from '@/models/learnerModels';

export class RolePlayCloudService extends BaseCloudService<RoleplayBuilder> {
  constructor() {
    super({ get: LEGACY_GET_ROLEPLAY, page: LEGACY_GET_ALL_ROLEPLAYS });
  }

  createRolePlaySession(rolePlayUuid?: string, journeyUuid?: string): Observable<RolePlaySessionDto> {
    return this.networkService.post<any>(
      `${ROOT_URL}/${RP_CREATE_SESSION}`,
      {
        rolePlayUuid,
        journeyUuid
      },
      undefined,
      false,
      true
    );
  }

  getBackgroundTask(uuid: string): Observable<BackgroundVerificationTaskResult> {
    return this.networkService.get<BackgroundVerificationTaskResult>(
      `${ML_URL}api/v1/backgroundTask/get?uuid=${uuid}`,
      undefined,
      false,
      true
    );
  }

  getStt(sessionUuid: string): Observable<RoleplayExecutionConfig> {
    return this.networkService.get<any>(`${ML_URL}${RP_STT}?sessionUuid=${sessionUuid}`, undefined, false, true);
  }

  initRolePlay(sessionUuid: string): Observable<RoleplayMessage> {
    return this.networkService.post<any>(`${ML_URL}${RP_INIT}`, { sessionUuid }, undefined, false, true);
  }

  logAction(payload: RoleplayLogAction): Observable<any> {
    return this.networkService.post<any>(`${ML_URL}${RP_LOG_ACTION}`, payload, undefined, false, true);
  }

  refreshRoleplaySummary(
    roleplay: string,
    journeyUuid?: string,
    executionType?: 'TESTING' | 'PRACTICE'
  ): Observable<RoleplayUserSummary> {
    return this.networkService.get<any>(
      `${ML_URL}${RP_USER_SUMMARY}${roleplay ? `?roleplay=${roleplay}` : ''}${
        journeyUuid ? `&journey=${journeyUuid}` : ``
      }&executionType=${executionType ?? 'TESTING'}`,
      undefined,
      false,
      true
    );
  }

  respond(
    payload: {
      sessionUuid: string;
      step: number;
      history: string[];
      learner_input: string;
      covered_steps?: number[];
      fail_occurred?: boolean;
    },
    apiV2?: boolean
  ): Observable<RoleplayMessage> {
    return this.networkService.post<any>(
      `${apiV2 ? ML_URL_V2 : ML_URL}${RP_INFERENCE}`,
      payload,
      undefined,
      false,
      true
    );
  }

  requestAzureToken(): Observable<any> {
    return this.networkService.get<any>(`${ML_URL}${RP_REQUEST_AZURE_TOKEN}`, undefined, false, true);
  }

  submitNaturalValidation(payload: any): Observable<BackgroundTask> {
    return this.networkService.post<any>(`${ML_URL}${SUBMIT_NATURAL}`, payload, undefined, false, true);
  }

  setRolePlayPhase = (payload: {
    roleplayUuid: string;
    journeyUuid: string;
    rolePlayModePhase: RolePlayModePhase;
  }): Observable<any> => {
    return this.networkService.patch<any>(`${ML_URL}${RP_SET_PHASE}`, payload, undefined, false, true);
  };
}
